export const appConfig = {
  appName: "app-ui",
  language: "de",
  routes: {
    default: "/",
    signIn: "/",
    token: "auth/token",
    api: "/api"
  },
};
