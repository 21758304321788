import { ServerResponse } from "@app/core/types";

export const isNullOrUndefined = (args) => args == null;

export const isSuccessAndPayloadExist = (
  response: ServerResponse<any>,
): boolean => {
  return (
    response.customStatus === 200 && Object.keys(response.payload).length > 0
  );
};
