export let Countries = [
  { key: "DEU", value: "Deutschland" },
  { key: "AFG", value: "Afghanistan" },
  { key: "EGY", value: "Ägypten" },
  { key: "ALB", value: "Albanien" },
  { key: "DZA", value: "Algerien" },
  { key: "AND", value: "Andorra" },
  { key: "AGO", value: "Angola" },
  { key: "AIA", value: "Anguilla" },
  { key: "ATA", value: "Antarktis" },
  { key: "ATG", value: "Antigua und Barbuda" },
  { key: "GNQ", value: "Äquatorial-Guinea" },
  { key: "ARG", value: "Argentinien" },
  { key: "ARM", value: "Armenien" },
  { key: "ABW", value: "Aruba" },
  { key: "AZE", value: "Aserbaidschan" },
  { key: "ETH", value: "Äthiopien" },
  { key: "AUS", value: "Australien" },
  { key: "BHS", value: "Bahamas" },
  { key: "BHR", value: "Bahrain" },
  { key: "BGD", value: "Bangladesch" },
  { key: "BRB", value: "Barbados" },
  { key: "BEL", value: "Belgien" },
  { key: "BLZ", value: "Belize" },
  { key: "BEN", value: "Benin" },
  { key: "BMU", value: "Bermuda" },
  { key: "BTN", value: "Bhutan" },
  { key: "BOL", value: "Bolivien" },
  { key: "BIH", value: "Bosnien-Herzegowina" },
  { key: "BWA", value: "Botsuana (Botswana)" },
  { key: "BRA", value: "Brasilien" },
  { key: "BRN", value: "Brunei Darussalam" },
  { key: "BGR", value: "Bulgarien" },
  { key: "BFA", value: "Burkina Faso (Obervolta)" },
  { key: "BDI", value: "Burundi" },
  { key: "CHL", value: "Chile" },
  { key: "CHN", value: "China" },
  { key: "COK", value: "Cook-Inseln" },
  { key: "CRI", value: "Costa Rica" },
  { key: "DNK", value: "Dänemark" },
  { key: "DMA", value: "Dominica" },
  { key: "DOM", value: "Dominikanische Republik" },
  { key: "DJI", value: "Dschibuti" },
  { key: "ECU", value: "Ecuador" },
  { key: "SLV", value: "El Salvador" },
  { key: "ERI", value: "Eritrea" },
  { key: "EST", value: "Estland" },
  { key: "FLK", value: "Falklandinseln" },
  { key: "FRO", value: "Färöer" },
  { key: "FJI", value: "Fidschi" },
  { key: "FIN", value: "Finnland" },
  { key: "FRA", value: "Frankreich" },
  { key: "PYF", value: "Französisch-Polynesien" },
  { key: "GAB", value: "Gabun" },
  { key: "GMB", value: "Gambia" },
  { key: "GEO", value: "Georgien" },
  { key: "GHA", value: "Ghana" },
  { key: "GIB", value: "Gibraltar" },
  { key: "GRD", value: "Grenada" },
  { key: "GRC", value: "Griechenland" },
  { key: "GRL", value: "Grönland" },
  { key: "GBR", value: "Großbritannien" },
  { key: "GUM", value: "Guam" },
  { key: "GTM", value: "Guatemala" },
  { key: "GIN", value: "Guinea" },
  { key: "GNB", value: "Guinea-Bissau" },
  { key: "GUY", value: "Guyana" },
  { key: "HTI", value: "Haiti" },
  { key: "HND", value: "Honduras" },
  { key: "HKG", value: "Hongkong" },
  { key: "IND", value: "Indien" },
  { key: "IDN", value: "Indonesien" },
  { key: "IRQ", value: "Irak" },
  { key: "IRN", value: "Iran" },
  { key: "IRL", value: "Irland" },
  { key: "ISL", value: "Island" },
  { key: "ISR", value: "Israel" },
  { key: "ITA", value: "Italien" },
  { key: "JAM", value: "Jamaika" },
  { key: "JPN", value: "Japan" },
  { key: "YEM", value: "Jemen (Arabische Republik)" },
  { key: "JOR", value: "Jordanien" },
  { key: "CYM", value: "Kaimaninseln" },
  { key: "KHM", value: "Kambodscha" },
  { key: "CMR", value: "Kamerun" },
  { key: "CAN", value: "Kanada" },
  { key: "CPV", value: "Kap Verde" },
  { key: "KAZ", value: "Kasachstan" },
  { key: "KEN", value: "Kenia" },
  { key: "KGZ", value: "Kirgisistan" },
  { key: "KIR", value: "Kiribati" },
  { key: "CCK", value: "Kokosinseln (Australien)" },
  { key: "COL", value: "Kolumbien" },
  { key: "COM", value: "Komoren" },
  { key: "COD", value: "Kongo" },
  { key: "HRV", value: "Kroatien" },
  { key: "CUB", value: "Kuba" },
  { key: "KWT", value: "Kuwait" },
  { key: "LAO", value: "Laos" },
  { key: "LSO", value: "Lesotho" },
  { key: "LVA", value: "Lettland" },
  { key: "LBN", value: "Libanon" },
  { key: "LBR", value: "Liberia" },
  { key: "LBY", value: "Libyen" },
  { key: "LIE", value: "Liechtenstein" },
  { key: "LTU", value: "Litauen" },
  { key: "LUX", value: "Luxemburg" },
  { key: "MAC", value: "Macao" },
  { key: "MDG", value: "Madagaskar" },
  { key: "MWI", value: "Malawi" },
  { key: "MYS", value: "Malaysia" },
  { key: "MDV", value: "Malediven" },
  { key: "MLI", value: "Mali" },
  { key: "MLT", value: "Malta" },
  { key: "MAR", value: "Marokko" },
  { key: "MHL", value: "Marshallinseln" },
  { key: "MRT", value: "Mauretanien" },
  { key: "MUS", value: "Mauritius" },
  { key: "MKD", value: "Mazedonien" },
  { key: "MEX", value: "Mexiko" },
  { key: "MOA", value: "Moldawien" },
  { key: "MON", value: "Monaco" },
  { key: "MNG", value: "Mongolei" },
  { key: "MNE", value: "Montenegro" },
  { key: "MOZ", value: "Mosambik" },
  { key: "MMR", value: "Myanmar"},
  { key: "NAM", value: "Namibia" },
  { key: "NRU", value: "Nauru" },
  { key: "NPL", value: "Nepal" },
  { key: "NCL", value: "Neukaledonien" },
  { key: "NZL", value: "Neuseeland" },
  { key: "NIC", value: "Nicaragua" },
  { key: "NLD", value: "Niederlande" },
  { key: "ANT", value: "Niederländische Antillen" },
  { key: "NER", value: "Niger" },
  { key: "NGA", value: "Nigeria" },
  { key: "NIU", value: "Niue" },
  { key: "NFK", value: "Norfolkinseln (Australien)" },
  { key: "NOR", value: "Norwegen" },
  { key: "OMN", value: "Oman" },
  { key: "AUT", value: "Österreich" },
  { key: "PAK", value: "Pakistan" },
  { key: "PLW", value: "Palau" },
  { key: "PAN", value: "Panama" },
  { key: "PNG", value: "Papua-Neuguinea" },
  { key: "PRY", value: "Paraguay" },
  { key: "PER", value: "Peru" },
  { key: "PHL", value: "Philippinen" },
  { key: "POL", value: "Polen" },
  { key: "PRT", value: "Portugal" },
  { key: "PUR", value: "Puerto Riko" },
  { key: "RKS", value: "Republik Kosovo" },
  { key: "SRB", value: "Republik Serbien" },
  { key: "RWA", value: "Ruanda" },
  { key: "ROU", value: "Rumänien" },
  { key: "RUS", value: "Russland" },
  { key: "ZMB", value: "Sambia" },
  { key: "ASM", value: "Samoa" },
  { key: "SMR", value: "San Marino" },
  { key: "STP", value: "Sao Tome und Principe" },
  { key: "SAU", value: "Saudi-Arabien" },
  { key: "SWE", value: "Schweden" },
  { key: "CHE", value: "Schweiz" },
  { key: "SEN", value: "Senegal" },
  { key: "SBM", value: "Serbien-Montenegro" },
  { key: "SYC", value: "Seyschellen" },
  { key: "SLE", value: "Sierra Leone" },
  { key: "SGP", value: "Singapur" },
  { key: "SVK", value: "Slowakei" },
  { key: "SVN", value: "Slowenien" },
  { key: "SOM", value: "Somalia" },
  { key: "ESP", value: "Spanien" },
  { key: "LKA", value: "Sri Lanka" },
  { key: "KOR", value: "Süd Korea" },
  { key: "ZAF", value: "Südafrika" },
  { key: "SDN", value: "Sudan" },
  { key: "SUR", value: "Suriname" },
  { key: "SWZ", value: "Swasiland" },
  { key: "SYR", value: "Syrien" },
  { key: "TJK", value: "Tadschikistan" },
  { key: "TWN", value: "Taiwan" },
  { key: "TZA", value: "Tansania" },
  { key: "THA", value: "Thailand" },
  { key: "TGO", value: "Togo" },
  { key: "TKL", value: "Tokelau" },
  { key: "TON", value: "Tonga" },
  { key: "TTO", value: "Trinidad und Tobago" },
  { key: "TCD", value: "Tschad" },
  { key: "CZE", value: "Tschechien" },
  { key: "TUN", value: "Tunesien" },
  { key: "TUR", value: "Türkei" },
  { key: "TKM", value: "Turkmenistan" },
  { key: "TCA", value: "Turks- und Caicos-Inseln" },
  { key: "TUV", value: "Tuvalu" },
  { key: "UGA", value: "Uganda" },
  { key: "UKR", value: "Ukraine" },
  { key: "HUN", value: "Ungarn" },
  { key: "URY", value: "Uruguay" },
  { key: "UZB", value: "Usbekistan" },
  { key: "VUT", value: "Vanuatu" },
  { key: "VAT", value: "Vatikanstadt" },
  { key: "VEN", value: "Venezuela" },
  { key: "ARE", value: "Vereinigte Arabische Emirate" },
  { key: "USA", value: "Vereinigte Staaten (USA)" },
  { key: "VNM", value: "Vietnam" },
  { key: "PRK", value: "Volksrepublik Korea" },
  { key: "WLF", value: "Wallis- und Futuna-Inseln" },
  { key: "BLR", value: "Weißrussland" },
  { key: "CAF", value: "Zentralafrikanische Republik" },
  { key: "ZWE", value: "Zimbabwe" },
  { key: "CYP", value: "Zypern" },
];
