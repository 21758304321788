import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/index";
import { environment } from "../../environments/environment";
import { AppState } from "../app.state";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl = environment.apiHostname;
  public token: string = null;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select("auth")
      .subscribe((auth: any) => (this.token = auth.token));
  }

  public authorizationToken() {
    return `Bearer ${this.token}`;
  }

  private getOptions(): any {
    return {
      headers: new HttpHeaders()
          .set("Content-Type", "application/json")
          .set("X-Timezone-Offset", this.getTimezoneOffset()),
    };
  }

  public download(
    endpoint: string,
    params: Object = {},
    type: string = "application/download",
    method = "get",
  ): Observable<any> {
    const options = <any>{
      responseType: "arraybuffer" as "json",
      headers: new HttpHeaders()
          .set("Content-Type", type)
          .set("Accept", type)
          .set("X-Timezone-Offset", this.getTimezoneOffset()),
    };

    const apiUrl = this.generateUrl(endpoint, params);

    return this.http[method](apiUrl, {}, { ...options, ...{ params } });
  }

  public downloadWithBody(
      endpoint: string,
      params: Object = {},
      body: Object = {},
      type: string = "application/download",
      method = "get",
  ): Observable<any> {
    const options = <any>{
      responseType: "arraybuffer" as "json",
      headers: new HttpHeaders()
          .set("Accept", type)
          .set("X-Timezone-Offset", this.getTimezoneOffset()),
    };

    const apiUrl = this.generateUrl(endpoint, params);

    return this.http.post(apiUrl, body, options);
  }

  public downloadFile(data: Blob, name: string, ext: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = `${name}.${ext}`;

    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public get(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.get(apiUrl, { ...options, ...{ params } });
  }

  public delete(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    options.body = params;
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.delete(apiUrl, options);
  }

  public put(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.put(apiUrl, params, options);
  }

  public patch(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.patch(apiUrl, params, options);
  }

  public post(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.post(apiUrl, params, options);
  }

  public generateUrl(endpoint: string, params: Object = {}): string {
    return `${this.baseUrl}/${endpoint}`;
  }

  private getTimezoneOffset(): string {
    return (String(new Date().getTimezoneOffset()));
  }
}
