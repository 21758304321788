import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerResponse } from "@app/core/types";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";
import {HttpUrlEncodingCodec} from "@app/core/util/HttpUrlEncodingCodec";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  // private basicAuth = btoa(`${environment.cliendId}:${environment.clientPass}`);
  private basicAuth = "dHVybnBvcnRhbF91aTpzRVJnQ1VIZXZVaG40VzVM";

  private headers: HttpHeaders = new HttpHeaders()
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("Authorization", `Basic ${this.basicAuth}`);

  constructor(private api: ApiService, private http: HttpClient) {}

  public signIn(payload: any): Observable<any> {
    const body = new HttpParams({encoder: new HttpUrlEncodingCodec()})
      .set("grant_type", "password")
      .set("username", payload.username)
      .set("password", payload.password)
      .toString();
    return this.http.post("oauth/token", body, { headers: this.headers });
  }

  public signInSso(payload: any): Observable<any> {
    const body = new HttpParams()
      .set("grant_type", "gymnet_token")
      .set("ssokey", payload.ssokey)
      .set("redirect", payload.redirect)
      .toString();
    return this.http.post("oauth/token", body, { headers: this.headers });
  }

  public refresh(refreshToken: string): Observable<any> {
    const body = new HttpParams()
      .set("grant_type", "refresh_token")
      .set("refresh_token", refreshToken)
      .toString();
    return this.http.post("oauth/token", body, { headers: this.headers });
  }

  public retriggerUnfinishedRegistration(username: string): Observable<any> {
    return this.http.post<ServerResponse>("retriggerUnfinishedRegistration", {
      username,
    });
  }

  getUser() {
    return this.api.get("users/me");
  }
}
