import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ServerResponse } from "@app/core/types";
import {
  NewCompetition,
  NewCompetitionResult,
  Participant,
  SingleCompetition,
} from "@app/core/user/competition/competition";
import { DateUtils } from "@app/shared/util/DateUtils";
import { Page } from "@app/core/types/Page";

@Injectable()
export class CompetitionService {
  constructor(private api: ApiService) {}

  getCompetition(id: string): Observable<SingleCompetition> {
    return this.api.get(`competition/${id}`).pipe(
      map((response) => {
        response.checkdate = DateUtils.convertServerStringToDate(
          <any>response.checkdate,
        );
        response.eventdate = DateUtils.convertServerStringToDate(
          <any>response.eventdate,
        );
        response.yearFrom = DateUtils.convertServerStringToDate(
          <any>response.yearFrom,
        );
        response.yearUntil = DateUtils.convertServerStringToDate(
          <any>response.yearUntil,
        );
        return response;
      }),
    );
  }

  getPDF(id: string): Observable<ServerResponse<string>> {
    return this.api.download(
      `competition/${id}/pdf`,
      {},
      "application/pdf",
      "post",
    );
  }

  getCSV(id: string): Observable<ServerResponse<string>> {
    return this.api.download(`competition/${id}/csv`, {},"text/csv", "post");
  }

  getParticipants(
    id: string,
    skip: number,
    limit: number,
    sort: { keyword: string; asc: boolean },
    keyword: string,
  ): Observable<Page<Participant[]>> {
    return this.api
      .get(`competition/participants/list/${id}`, {
        query: keyword,
        pageSize: limit,
        pageNumber: skip,
        sortDir: sort.asc ? "asc" : "desc",
        sortBy: sort.keyword,
      })
      .pipe(
        map((response) => {
          const content = response?.content?.map((participant) => {
            participant.dateOfBirth = DateUtils.convertServerStringToDate(
              <any>participant.dateOfBirth,
            );
            return participant;
          });

          return { ...response, content };
        }),
      );
  }

  getList(
    skip: number,
    limit: number,
    sort: { keyword: string; asc: boolean },
    keyword: string = "",
  ): Observable<any> {
    return this.api.get("competition/list", {
      query: keyword,
      pageSize: limit,
      pageNumber: skip,
      sortDir: sort.asc ? "asc" : "desc",
      sortBy: sort.keyword,
    });
  }

  createCompetition(
    competitionData: NewCompetition,
  ): Observable<NewCompetitionResult> {
    return this.api.post("competition", competitionData);
  }

  deleteCompetition(id: string): Observable<ServerResponse<void>> {
    return this.api.delete(`competition/${id}`);
  }
}
