import { Observable, Observer, throwError, forkJoin } from "rxjs";
import { FileSizeError, FileTypeError } from "@app/core/types";
import { mergeMap, map } from "rxjs/operators";

export class ImageUtils {
  public static convertToBase64(file: File | Blob): Observable<string> {
    return Observable.create((observer) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileType = file.type.toLowerCase();
        if (
          fileType == "image/jpeg" ||
          fileType == "image/jpg" ||
          fileType == "image/png"
        ) {
          let base64 = "data:" + file.type + ";base64,";
          base64 += (reader.result as string).split(",")[1];
          observer.next(base64);
          observer.complete();
        } else {
          observer.error(new FileTypeError());
          observer.complete();
        }
      };
    });
  }
  public static getBase64Url(file: File | Blob): Observable<string> {
    return this.convertToBase64(file).pipe(map((base64) => `url(${base64})`));
  }

  public static rotateAndConvert(file: File | Blob): Observable<string> {
    if (this.isBiggerThan5MB(file)) {
      return throwError(new FileSizeError());
    }
    return forkJoin(this.convertToBase64(file), this.getOrientation(file)).pipe(
      mergeMap(([base64Source, orientation]) =>
        this.resetOrientation(base64Source, orientation),
      ),
    );
  }

  static isBiggerThan5MB(file: File | Blob): boolean {
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > 5) {
      return true;
    }
    return false;
  }

  static getOrientation(file: File | Blob): Observable<number> {
    return Observable.create((observer: Observer<number>) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        const view = new DataView((<any>e).target.result);
        if (view.getUint16(0, false) != 0xffd8) {
          observer.next(-2);
          observer.complete();
          return;
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) {
            observer.next(-1);
            observer.complete();
            return;
          }
          let marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xffe1) {
            if (view.getUint32((offset += 2), false) != 0x45786966) {
              observer.next(-1);
              observer.complete();
              return;
            }
            const little = view.getUint16((offset += 6), false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) == 0x0112) {
                observer.next(view.getUint16(offset + i * 12 + 8, little));
                observer.complete();
                return;
              }
            }
          } else if ((marker & 0xff00) != 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        observer.next(-1);
        observer.complete();
      };
      reader.onerror = () => {};
      reader.readAsArrayBuffer(file);
    });
  }

  static resetOrientation(
    srcBase64: string,
    srcOrientation: number,
  ): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      let img = new Image();
      img.onload = () => {
        const width = img.width,
          height = img.height;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);
        // export base64
        const base64 = canvas.toDataURL();
        observer.next(`url(${base64})`);
        observer.complete();
      };

      img.src = srcBase64;
    });
  }
}
