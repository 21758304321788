export class CoreState {
  public isOnline: boolean;
  public isLoading: boolean;
  public language = "en";
  public sideNavOpened: boolean;
  public title: string;
  public back: boolean;
  public redirectUrl: string | undefined;
  public welcomeSectionDismissed = false;
}
