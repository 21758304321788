import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { ServerResponse } from "@app/core/types";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { Observable, BehaviorSubject } from "rxjs";
import { ClubData } from "@app/core/types/ClubData";
import { map, share, filter, tap } from "rxjs/operators";
import { clubsFetched } from "@app/store/club/club.actions";

@Injectable({
  providedIn: "root",
})
export class ClubService {
  private _associatedClubs = new BehaviorSubject<ClubData[]>(null);
  private userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  assignToClub(clubId: string): Observable<ServerResponse> {
    return this.api.post(
      `gymnasts/${this.userProfile.uuid}/clubs?clubId=${clubId}`,
      {},
    );
  }

  withdrawFromClub(clubs: ClubData[]): Observable<ServerResponse> {
    let params = new HttpParams();

    clubs.forEach((club) => {
      params = params.append("clubIds", club.id);
    });

    return this.api.delete(
      `gymnasts/${this.userProfile.uuid}/clubs?${params.toString()}`,
    );
  }

  getForeignClubs(userId: string, searchTerm: string): Observable<ClubData[]> {
    const params = new HttpParams()
      .set("query", searchTerm)
      .set("pageNumber", "0")
      .set("pageSize", "100");
    return this.api
      .get(`gymnasts/${userId}/clubs/foreign?${params.toString()}`)
      .pipe(map((resp: any) => resp.content));
  }

  refreshAssociatedClubs(): Observable<ClubData[]> {
    const request = this.getAssociatedClubs().pipe(
      map((response: any) => response),
      share(),
    );
    request.subscribe((data) => {
      this._associatedClubs.next(data);
    });
    return request;
  }

  getAssociatedClubs(
    uuid: string = this.userProfile.uuid,
  ): Observable<ClubData[]> {
    return this.api
      .get(`gymnasts/${uuid}/clubs`)
      .pipe(map((resp: any) => resp.content));
  }

  get associatedClubs(): Observable<ClubData[]> {
    return this._associatedClubs.pipe(filter((data) => Boolean(data)));
  }

  get firstFiveAssociatedClubs(): Observable<ClubData[]> {
    return this.associatedClubs.pipe(
      map((data) =>
        data
          .sort((clubA, clubB) => this.sortAlphabetically(clubA, clubB))
          .slice(0, 5),
      ),
    );
  }

  private sortAlphabetically(ClubA: ClubData, ClubB: ClubData): number {
    if (!ClubA.name || !ClubB.name) {
      return -1;
    }
    return ClubA.name.localeCompare(ClubB.name);
  }

  cleanUp() {
    this._associatedClubs.next(null);
  }
}
