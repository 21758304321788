import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";

export class CustomHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const nextReq = req.clone({
      setHeaders: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    });
    return next.handle(nextReq);
  }
}
