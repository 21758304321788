import { Injectable } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AyService {
  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {}

  i18n(key: string, parameters: any = {}): string {
    return this.translate.instant(key, parameters);
  }

  msg(message: string): void {
    this.snackBar.open(this.i18n(message), this.i18n("CLOSE"), {
      duration: 3000,
    });
  }

  public matDialogConfig(data: any = {}): MatDialogConfig {
    const options = {
      hasBackdrop: true,
      autoFocus: true,
    } as MatDialogConfig;

    return { ...options, ...data } as MatDialogConfig;
  }
}

export const dialogConfig = (
  data: any,
  config?: MatDialogConfig,
): MatDialogConfig => {
  const defaultConfig: MatDialogConfig = {
    ...{
      autoFocus: false,
      data,
    },
    ...config,
  };
  return defaultConfig;
};
