import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { pluck } from "rxjs/operators";

declare type AvailableLanguages = "en" | "de";

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(
    language: AvailableLanguages,
  ): Observable<{ [key: string]: string }> {
    return from(System.import(`./${language}-translations.ts`)).pipe(
      pluck("TRANSLATIONS"),
    );
  }
}
