import { NgModule, SkipSelf, Optional } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RegistrationService } from "@app/core/auth";
import {
  SearchGymnastsService,
  GymnastService,
  UserImageService,
  PurchaseService,
  LicenceService,
  StartingLicenceService,
  CompetitionService,
  SearchStartingLicenceService,
  StartingLicenceDetailsService,
  ContactsAdminService,
  ClubAdminService,
} from "@app/core/user";
import { AuthUserDenyGuard } from "@app/core/auth/auth-user-deny-guard";
import { CustomHttpInterceptor } from "@app/core/util/HttpInterceptor";
import { MembershipService } from "@app/core/user/membership/membership.service";
import { PendingLicenceService } from "@app/core/user/starting-licence/pending-licence.service";
import { ClubService } from "@app/core/user/club/club.service";
import { ContactDtbAdminService } from "./user/dtb-admin/dtb-admin.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthUserDenyGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    RegistrationService,
    GymnastService,
    StartingLicenceService,
    SearchStartingLicenceService,
    SearchGymnastsService,
    UserImageService,
    PurchaseService,
    LicenceService,
    CompetitionService,
    MembershipService,
    PendingLicenceService,
    StartingLicenceDetailsService,
    ClubService,
    ContactsAdminService,
    ClubAdminService,
    ContactDtbAdminService,
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import only in AppModule");
    }
  }
}
