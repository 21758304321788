import { SelectionModel } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { ItemCheckboxData } from "@app/views/user/components/starting-licence-for-gymnasts/starting-licence-table-item/item-checkbox-data";

@Injectable()
export class StartingLicenceDataSource {
  public loadedItems: Array<ItemCheckboxData> = [];

  private selection = new SelectionModel<ItemCheckboxData>(true);
  public readonly selectionChanged$ = this.selection.changed.asObservable();
  public pageNumber = 0;

  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      const newSelection = this.loadedItems.filter((item) =>
        this.isSelectable(item),
      );

      this.selection.select(...newSelection);
    }
  }

  isSelected(item) {
    return this.selection.isSelected(item);
  }

  isAllSelected() {
    const numRows = this.loadedItems.filter((item) => this.isSelectable(item))
      .length;
    if (numRows === 0) {
      return false;
    }
    const numSelected = this.selection.selected.length;
    return numSelected === numRows;
  }

  hasSelected() {
    if (this.loadedItems.length === 0) {
      return false;
    }
    return this.selection.hasValue();
  }

  hasSelectable() {
    return (
      this.loadedItems.length > 0 &&
      this.loadedItems.some((item) => this.isSelectable(item))
    );
  }

  toggleSelection(id: string) {
    const data = this.loadedItems.find(
      (item) => item.data.startingLicenceId === id,
    );
    if (data) {
      this.toggleItem(data);
    }
  }

  setItems(data: ItemCheckboxData[]) {
    this.loadedItems = data;
    this.pageNumber = 1;
    this.selection.clear();
  }

  clearItems() {
    this.loadedItems = [];
    this.pageNumber = 0;
    this.selection.clear();
  }

  addItems(data: ItemCheckboxData[]) {
    this.loadedItems = this.loadedItems.concat(data);
    this.pageNumber += 1;
  }

  getSelectedItems() {
    return this.selection.selected;
  }

  get numItems() {
    return this.loadedItems.length;
  }

  private isSelectable(item: ItemCheckboxData) {
    return item.enablement.state === "enabled" && item.data.discipline !== "RSG Liga";
  }

  private toggleItem(item: ItemCheckboxData) {
    if (item && this.isSelectable(item)) {
      this.selection.toggle(item);
    }
  }
}
