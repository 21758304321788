import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";
import { PurchaseData, ServerResponse } from "@app/core/types";
import { AnnualStamp } from "./annual-stamp";
import { BankAccount } from "./bank-account";
import { map } from "rxjs/operators";

@Injectable()
export class PurchaseService {
  constructor(private api: ApiService) {}

  requestDtbId(data: PurchaseData): Observable<ServerResponse> {
    return this.api.post("purchase/dtbid", data);
  }

  calculateAnnualStamp(
    gymnastId: string,
    disciplineIds: string[],
    clubId: string = null,
  ): Observable<AnnualStamp[]> {
    return this.api.post("purchase/calculate/new", {
      gymnastId,
      disciplineIds,
      clubId,
    });
  }

  calculateMultipleAnnualStamps(
    gymnastIds: string[],
    disciplineIds: string[],
    clubId: string = null,
  ): Observable<AnnualStamp[]> {
    return this.api.post("purchase/calculateMultipleAnnualStamps/new", {
      gymnastIds,
      disciplineIds,
      clubId,
    });
  }

  calculateForeignAnnualStamp(
    gymnastId: string,
    startinglicenceIds: string[],
  ): Observable<AnnualStamp[]> {
    return this.api
      .post("/purchase/calculateForeignStartinglicences/new", {
        gymnastId,
        startinglicenceIds,
      })
      .pipe(
        map((data = []) => {
          return data;
        }),
      );
  }

  calculateExtendAnnualStamp(
    gymnastId: string,
    startinglicenceIds: string[],
  ): Observable<AnnualStamp[]> {
    return this.api.post("/purchase/calculateExtendedStartinglicences/new", {
      gymnastId,
      startinglicenceIds,
    });
  }

  calculateExtendMultipleAnnualStamps(
    startinglicenceIds: string[],
  ): Observable<AnnualStamp[]> {
    return this.api.post(
      "/purchase/calculateExtendedStartinglicencesForMultipleGymnasts/new",
      {
        startinglicenceIds,
      },
    );
  }

  purchaseAnnualStamp(
    gymnastId: string,
    disciplineIds: string[],
    sepaAgreementSet: boolean,
    sepaId: string,
    clubId: string = null,
  ): Observable<ServerResponse<AnnualStamp>> {
    return this.api.post("purchase/annualstamp", {
      gymnastId,
      disciplineIds,
      sepaAgreementSet,
      sepaId,
      clubId,
    });
  }

  purchaseMultipleAnnualStamps(
    gymnastIds: string[],
    disciplineIds: string[],
    sepaAgreementSet: boolean,
    sepaId: string,
  ): Observable<ServerResponse<AnnualStamp>> {
    return this.api.post("purchase/multipleAnnualStamps", {
      gymnastIds,
      disciplineIds,
      sepaAgreementSet,
      sepaId,
    });
  }

  purchaseForeignAnnualStamp(
    gymnastId: string,
    startinglicenceIds: string[],
    sepaAgreementSet: boolean,
    sepaId: string,
  ): Observable<ServerResponse<AnnualStamp>> {
    return this.api.post(`gymnasts/${gymnastId}/licences/foreign/overtake`, {
      licenceUuids: startinglicenceIds,
      sepaAgreementSet,
      sepaId,
    });
  }

  purchaseExtendAnnualStamp(
    gymnastId: string,
    startinglicenceIds: string[],
    sepaAgreementSet: boolean,
    sepaId: string,
  ): Observable<ServerResponse<AnnualStamp>> {
    return this.api.post("purchase/annualstamp/extendedStartinglicences", {
      gymnastId,
      startinglicenceIds,
      sepaAgreementSet,
      sepaId,
    });
  }

  purchaseExtendMultipleAnnualStamps(
    startinglicenceIds: string[],
    sepaAgreementSet: boolean,
    sepaId: string,
  ): Observable<ServerResponse<AnnualStamp>> {
    return this.api.post(
      "/purchase/annualstamp/extendedStartinglicencesMultipleGymnasts",
      {
        startinglicenceIds,
        sepaAgreementSet,
        sepaId,
      },
    );
  }

  getBankAccounts(clubId: string = null): Observable<BankAccount[]> {
    if (clubId === null) {
      return this.api.get("purchase/sepa/ownclub");
    } else {
      return this.api.get("purchase/sepa/ownclub", { clubId });
    }
  }
}
