import { Component, HostListener } from "@angular/core";
import { environment } from "@env/environment";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private meta: Meta) {
    if (environment.testSystem) {
      this.meta.addTag({ name: "robots", content: "noindex" });
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander(event) {
    return false;
  }
}
