import { IProfile } from "@app/core/auth/Profile";
import { Role } from "@app/core/auth/Role";
import { plainToClassFromExist } from "class-transformer";
import { ClubData } from "@app/core/types/ClubData";

export class Profile implements IProfile {
  role: Role;
  uuid: string;
  authorities: string[];
  writable: boolean;
  associatedClubs: ClubData[] = [];

  constructor(entity: IProfile = null) {
    plainToClassFromExist(this, entity);
  }

  get clubId(): string {
    const club = this.authorities.find((x) => x.includes("role_club_admin_"));
    return club?.replace("role_club_admin_", "");
  }

  get club(): ClubData {
    return this.associatedClubs.find((club) => club.id === this.clubId);
  }

  get isClubAdmin(): boolean {
    return (
      this.authorities &&
      this.authorities.findIndex((x) => x.includes("role_club_admin_")) >= 0
    );
  }

  get isGymnast(): boolean {
    return (
      !this.isClubAdmin &&
      this.authorities &&
      this.authorities.findIndex((permission) => permission === "role_user") >=
        0
    );
  }

  get isCompetitionOrganizer(): boolean {
    return (
      this.authorities &&
      this.authorities.findIndex((x) => x.includes("role_organizer")) >= 0
    );
  }

  get isContactAdmin(): boolean {
    return (
      this.authorities &&
      this.authorities.findIndex((x) => x.includes("role_contact_")) >= 0
    );
  }

  get isContactDtbAdmin(): boolean {
    return (
      this.authorities &&
      this.authorities.findIndex(x => x === 'role_contact_dtb') >= 0
    )
  }

  get profileUrl(): string {
    return `/master-data-detailview/${this.uuid}`;
  }
}
