import { DateTime, Duration } from "luxon";

const CLIENT_DATE_FORMAT = `dd.MM.yyyy`;
const CLIENT_DATE_FORMAT_SHORT = `d.M.yyyy`;
export const LOWEST_POSSIBLE_DATE = new Date(1900, 0, 1);

export class DateUtils {
  public static convertServerStringToDate(value: string): Date {
    return value ? DateTime.fromISO(value).toJSDate() : undefined;
  }

  public static getClientDateString(date: any): string {
    return DateTime.fromJSDate(date).toFormat(CLIENT_DATE_FORMAT);
  }

  public static getDateString(date: any): string {
    if (date.format) {
      return date.format("yyyy-MM-DD");
    }
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  }

  public static convertDateToServerString(date: Date): string | null {
    return DateTime.fromJSDate(date).toISODate();
  }

  public static convertInputStringToClientDate(value: string): Date {
    return DateTime.fromString(value, CLIENT_DATE_FORMAT_SHORT).toJSDate();
  }

  public static convertClientDateToInputString(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(CLIENT_DATE_FORMAT);
  }

  public static getDiffNow(date: Date): Duration {
    return DateTime.fromJSDate(date).diffNow();
  }

  public static isUnder16(date: Date): boolean {
    const diff = new Date(Date.now() - date.getTime());
    return diff.getFullYear() - 1970 < 16;
  }

  public static isInFuture(date: Date): boolean {
    return date > new Date();
  }

  public static isToday(date: Date): boolean {
    if (!date) {
      return false;
    }
    const startOfDay = DateTime.fromJSDate(new Date()).startOf("day");
    const paramDate = DateTime.fromJSDate(date);
    const dayDiff = Math.ceil(startOfDay.diff(paramDate, "days").days);
    return dayDiff === 0;
  }

  public static isInPast(date: Date): boolean {
    return date ? date < new Date() : false;
  }

  public static isBeforeLowestPossible(date: Date): boolean {
    return date < LOWEST_POSSIBLE_DATE;
  }

  public static compare(d1: Date, d2: Date): number {
    return +(d1 > d2) - +(d1 < d2);
  }
}
