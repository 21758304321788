import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { appConfig } from "../../app.config";
import { AppState } from "../../app.state";
import { CoreActions } from "../../store/core/core.actions";
import { AuthState } from "../../store/auth/auth.state";

@Injectable()
export class NewAuthGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.select("auth").pipe(
      map((auth: AuthState) => {
        const redirectUrl = window.location.href
          .toString()
          .split(window.location.host)[1];

        const test = !!(
          auth != null &&
          auth !== undefined &&
          auth.accessToken &&
          auth.user
        );
        if (!test) {
          this.store.dispatch({
            type: CoreActions.SET_REDIRECT_URL,
            payload: { redirectUrl },
          });

          console.log("redirect!", redirectUrl);

          this.router.navigate([appConfig.routes.signIn]);
        }
        return test;
      }),
    );
  }
}

@Injectable()
export class NewUnAuthGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.select("auth").pipe(
      map((auth: AuthState) => {
        return !!(
          auth == null ||
          auth === undefined ||
          !auth.accessToken ||
          !auth.user
        );
      }),
    );
  }
}
