import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthApiService } from "@app/auth/auth-api.service";
import { Role } from "@app/core/auth";
import { Profile } from "@app/core/auth/Profile.model";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { appConfig } from "@app/app.config";
import { AppState } from "@app/app.state";
import { AyService } from "@app/ay.service";
import { CoreActions } from "../core/core.actions";
import { getRedirectUrl } from "../core/core.selectors";
import { AuthActions } from "./auth.actions";
import { getRefreshToken } from "./auth.selectors";
import { fetchAssociatedClubs } from "@app/store/club/club.actions";

@Injectable()
export class AuthEffects {
  private redirectUrl: string;
  private refreshToken: string;

  constructor(
    private store: Store<AppState>,
    private ay: AyService,
    private router: Router,
    private actions$: Actions,
    private api: AuthApiService,
  ) {
    this.store.select(getRedirectUrl).subscribe((x) => (this.redirectUrl = x));
    this.store
      .select(getRefreshToken)
      .subscribe((refreshToken: string) => (this.refreshToken = refreshToken));
  }

  @Effect({ dispatch: false })
  backToLogin$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_OUT),
    tap(() => this.router.navigate([appConfig.routes.signIn])),
  );

  @Effect()
  signInSso$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN_SSO),
    map((action: any) => action.payload),
    mergeMap((credentials) =>
      this.api.signInSso(credentials).pipe(
        map((payload) => ({ type: AuthActions.SIGN_IN_SUCCESS, payload })),
        catchError(({ error }) => {
          if (error.error === "invalid_grant") {
            this.router.navigate(["error/404"]);
          } else if (error.error === "invalid_request") {
            this.router.navigate(["error/club"]);
          }
          return of({ type: AuthActions.SIGN_IN_ERROR, payload: error });
        }),
      ),
    ),
  );

  @Effect()
  signIn$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN),
    map((action: any) => action.payload),
    mergeMap((credentials) =>
      this.api.signIn(credentials).pipe(
        map((payload) => ({ type: AuthActions.SIGN_IN_SUCCESS, payload })),
        catchError(({ error }) =>
          of({ type: AuthActions.SIGN_IN_ERROR, payload: error }),
        ),
      ),
    ),
  );

  @Effect()
  signInError$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN_ERROR),
    map((x: any) => {
      // this.ay.msg(`${x.payload.toString()}`);
      return { type: CoreActions.LOADED };
    }),
  );

  @Effect()
  refreshUser$ = this.actions$.pipe(
    ofType(AuthActions.REFRESH_USER),
    map((action: any) => action),
    mergeMap(() => {
      return this.api.getUser().pipe(
        map((payload) => ({ type: AuthActions.REFRESH_USER_SUCCESS, payload })),
        catchError(({ error }) =>
          of({ type: AuthActions.REFRESH_USER_ERROR, payload: error }),
        ),
      );
    }),
  );

  @Effect()
  refreshToken$ = this.actions$.pipe(
    ofType(AuthActions.REFRESH_TOKEN),
    map((action: any) => action.payload),
    mergeMap(() =>
      this.api.refresh(this.refreshToken).pipe(
        map((payload) => ({ type: AuthActions.SET_TOKEN, payload })),
        catchError(({ error }) =>
          of({ type: AuthActions.SIGN_IN_ERROR, payload: error }),
        ),
      ),
    ),
  );

  @Effect()
  signInSuccess$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN_SUCCESS),
    map((action: any) => {
      // @todo get value from resp
      const profile = new Profile({
        writable: true,
        role: Role.Gymnast,
        uuid: action.payload.user.uuid,
        authorities: action.payload.user.authorities,
      });

      this.store.dispatch(fetchAssociatedClubs({ userId: profile.uuid }));

      if (this.redirectUrl) {
        this.store.dispatch({ type: CoreActions.REDIRECT_URL_RESET });
        this.router.navigateByUrl(this.redirectUrl);
      } else {
        if (profile.isClubAdmin) {
          this.router.navigate(["/show-gymnasts"]);
        } else if (profile.isCompetitionOrganizer) {
          this.router.navigate(["/competition-list"]);
        } else if (profile.isContactAdmin) {
          this.router.navigate(["/contacts"]);
        } else {
          this.router.navigate([profile.profileUrl]);
        }
      }
      return { type: CoreActions.LOADED };
    }),
  );
}
