import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ServerResponse } from "@app/core/types";
import { isNullOrUndefined } from "@app/core/util/check-functions";
import {
  LicenceData,
  LicenceDataWithConflict,
  LicenceConflicts,
  Discipline,
  SaveNote,
} from "./licence-data";

@Injectable({
  providedIn: "root",
})
export class LicenceService {
  constructor(private api: ApiService) {}

  getStartingLicencesWithConflicts(
    userIds: Array<string>,
    clubId: string = null
  ): Observable<LicenceDataWithConflict[]> {
    return combineLatest(
      [this.getAvailableStartingLicences(userIds, clubId),
      this.getStartingLicencesConflicts()],
    ).pipe(
      map(([licences, conflicts]) => {
        return licences.map((licence) => {
          const { guid } = licence;
          const conflictsForId = conflicts[guid] || [];
          return { ...licence, conflicts: conflictsForId };
        });
      }),
    );
  }

  getAllDisciplines(): Observable<Discipline[]> {
    return this.api.get("discipline/list/all");
  }

  assumeStartingLicences(
    gymnastId: string,
    licenceIds: string[],
  ): Observable<unknown> {
    return this.api.post(`gymnasts/${gymnastId}/licences/foreign/overtake`, {
      licenceUuids: licenceIds,
    });
  }

  saveStartingLicences(
    gymnastId: string,
    disciplineIds: string[],
    clubId: string = null,
  ): Observable<any> {
    return this.api.post("licences/save", {
      gymnastId,
      disciplineIds,
      clubId,
    });
  }

  saveForeignStartingLicences(
    gymnastId: string,
    startinglicenceIds: string[],
  ): Observable<ServerResponse> {
    return this.api.post("foreignStartinglicences", {
      gymnastId,
      startinglicenceIds,
    });
  }

  saveExtendStartingLicences(
    gymnastId: string,
    startinglicenceIds: string[],
  ): Observable<ServerResponse> {
    return this.api.post("extendedStartinglicences", {
      gymnastId,
      startinglicenceIds,
    });
  }

  saveExtendStartingLicencesMultiple(
    startinglicenceIds: string[],
  ): Observable<ServerResponse> {
    return this.api.post("extendedStartinglicencesForMultipleGymnasts", {
      startinglicenceIds,
    });
  }

  addNote(data: SaveNote): Observable<ServerResponse> {
    return this.api.post("lock", data);
  }

  private getAvailableStartingLicences(
    userIds: Array<string>,
    clubId: string = null
  ): Observable<LicenceData[]> {
    if (userIds.length == 1 && userIds[0]) {
      return this.api.get(`discipline/list/${userIds}`, {
        clubId
      });
    } else {
      return this.api.post(`discipline/listForMultipleGymnasts`, {
        userIds
      })
    }
  }

  private getStartingLicencesConflicts(): Observable<LicenceConflicts> {
    return this.api.get("discipline/list/conflicts").pipe(
      map((payload) => {
        const nullSafePayload = isNullOrUndefined(payload) ? {} : payload;
        return nullSafePayload;
      }),
    );
  }
}
