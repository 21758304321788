import { Role } from "@app/core/auth";
import { Profile } from "@app/core/auth/Profile.model";
import { createSelector } from "@ngrx/store";
import { AppState } from "../../app.state";
import { AuthState } from "./auth.state";

export const authObj = (appState: AppState) => appState.auth;

export const isLogged = createSelector(
  authObj,
  (state: AuthState) => !!getTokenValue(state),
);
export const isAuthLoading = createSelector(
  authObj,
  (state: AuthState) => state.loading,
);
export const getToken = createSelector(authObj, (state: AuthState) =>
  getTokenValue(state),
);
export const getRefreshToken = createSelector(authObj, (state: AuthState) =>
  getRefreshTokenValue(state),
);

export const getUser = createSelector(
  authObj,
  (state: AuthState) => new Profile(state.user),
);

// export const hasPermission = (permission: string) => createSelector(authObj, (state: AuthState) => {
//   if (!state.user) {
//     return false;
//   }
//   const permissionScope = state.permissions;
//   return permissionScope.findIndex(x => x === permission) >= 0;
// });

const getTokenValue = (state: AuthState) => state.accessToken;
const getRefreshTokenValue = (state: AuthState) => state.refreshToken;
