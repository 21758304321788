import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthApiService } from "@app/auth/auth-api.service";
import { environment } from "@env/environment";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { appConfig } from "@app/app.config";
import { AppState } from "@app/app.state";
import { AuthActions } from "@app/store/auth/auth.actions";
import { getRefreshToken, getToken } from "@app/store/auth/auth.selectors";
import { AyService } from "@app/ay.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  protected token: string = null;
  protected refreshToken: string = null;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private auth: AuthApiService,
    private ay: AyService,
  ) {
    this.store
      .select(getToken)
      .subscribe((token: string) => (this.token = token));
    this.store
      .select(getRefreshToken)
      .subscribe((refreshToken: string) => (this.refreshToken = refreshToken));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const temp = next;

    return next.handle(this.addAuthenticationToken(request)).pipe(
      tap(() => {}),
      catchError((response) => {
        switch (response.status) {
          case 0:
            const message = this.ay.i18n(
              "common.snackBar.networkConnectionChanged",
              { statusName: "offline" },
            );
            this.ay.msg(message);
            return throwError(response);
          case 401:
            if (request.url.includes(appConfig.routes.token)) {
              this.ay.msg(response.error.message);
              this.logout();
              return throwError(response);
            } else if (request.url.includes(appConfig.routes.signIn)) {
              return throwError(response);
            } else if (request.url.includes(appConfig.routes.api)) {
              this.ay.msg("LOGGED_OUT");
              this.logout();
              return throwError(response);
            }
            break;
          case 400:
            this.ay.msg(`400_${response.error.code}`);
            return throwError(response);
          case 500:
            this.ay.msg(response.error.message);
            return of(response);
          default:
            return throwError(response);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))),
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);

          return this.auth.refresh(this.refreshToken).pipe(
            switchMap((payload: any) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(payload.access_token);

              this.store.dispatch({ type: AuthActions.SET_TOKEN, payload });
              this.token = payload.accessToken;

              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((err: any) => {
              this.refreshTokenInProgress = false;
              return throwError(response);
            }),
          );
        }
      }),
    );
  }

  logout() {
    this.store.dispatch({ type: AuthActions.SIGN_OUT });
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    if (!request.url.includes(environment.apiHostname)) {
      return request;
    }

    if (!this.token || request.url.includes(appConfig.routes.token)) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }
}
