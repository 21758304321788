import { Injectable } from "@angular/core";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { StartingLicenceDetails } from "@app/core/types/StartingLicenceDetails";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Injectable()
export class StartingLicenceDetailsService {
  userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  getStartingLicenceDetails(
    licenceId: string,
  ): Observable<StartingLicenceDetails> {
    if (this.userProfile.isCompetitionOrganizer) {
      return this.api.get(`competing/${licenceId}`);
    } else {
      return this.api.get(`masterdata/${licenceId}`);
    }
  }
}
