import { Injectable } from "@angular/core";

@Injectable()
export class ClubAdminService {

    filters = {
        contacts: [],
        licenses: [],
    };
    items = {
        gymnasts: [],
        licenses: [],
    };

    sorts = {
        gymnasts: {
            keyword: "lastName",
            asc: true,
        },
        licenses: {
            keyword: "expirationDate",
            asc: false,
        },
    };

    queries = {
        gymnasts: '',
        licenses: {
            search: '',
            discipline: '',
        },
    };

    scrollbars = {
        gymnasts: 0,
        licenses: 0,
    };
}