import { Injectable } from "@angular/core";
import { PendingStartingLicence, PendingStartingLicences, RawPendingStartingLicence, ServerResponse } from "@app/core/types";
import { StartingLicenceService } from "@app/core/user/starting-licence/starting-licence.service";
import { ApiService } from "@app/shared/api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, share } from "rxjs/operators";

@Injectable()
export class PendingLicenceService {
  constructor(
    private api: ApiService,
    private startingLicenceService: StartingLicenceService,
  ) {}

  private _pendingLicences = new BehaviorSubject<RawPendingStartingLicence[]>(
    [],
  );

  public get pendingLicences(): Observable<RawPendingStartingLicence[]> {
    return this._pendingLicences.pipe(
      map((data) => this.groupPendingLicences(data)),
    );
  }

  getPendingLicencesForClubForCurrentGymnast(): Observable<PendingStartingLicences | null> {
    return this.startingLicenceService.licencesForMyClub.pipe(
      map((licences) =>
        (licences || []).filter((licence) => licence.status === "APPLIED"),
      ),
      map((pendingLicences) => {
        if (pendingLicences.length === 0) {
          return null;
        }
        return pendingLicences.reduce((acc, pendingLicence) => {
          const disciplines = acc.disciplines ? acc.disciplines : [];
          return {
            clubName: pendingLicence.clubName,
            clubId: pendingLicence.clubId,
            disciplines: disciplines.concat([pendingLicence.discipline]),
          };
        }, {} as PendingStartingLicences);
      }),
    );
  }

  getPendingLicencesForClub(
    clubId: string,
  ): Observable<PendingStartingLicence[]> {
    return this.api.get(`list/own/pending/${clubId}`);
  }

  refusePendingLicences(
    clubId: string,
    adminId: string,
  ): Observable<unknown> {
    return this.api.post(`refuse/for/${clubId}?adminId=${adminId}`);
  }

  refusePendingLicencesByUuid(licencesUuid: string[]): Observable<unknown> {
    return this.api.post(`refuse/licences/byUuid`, {licencesUuid});
  }

  refreshPendingLicences() {
    const request = this.api.get("list/own/pending").pipe(
      map((response) => {
        let data: RawPendingStartingLicence[] = [];
        data = response;
        return data;
      }),
      share(),
    );
    request.subscribe((data) => {
      this._pendingLicences.next(data);
    });
    return request;
  }

  public confirmStartingLicences(clubId: string): Observable<ServerResponse> {
    return this.api.post("confirm/for/" + clubId, null);
  }

  public refuseStartingLicences(clubId: string): Observable<ServerResponse> {
    return this.api.post("refuse/for/" + clubId, null);
  }

  public applySubmittedLicences(clubIdsToDelete: string[]) {
    const currentValues = this._pendingLicences.value;
    const newValues = currentValues.filter(
      (licence) => clubIdsToDelete.indexOf(licence.club.id) < 0,
    );
    this._pendingLicences.next(newValues);
  }

  cleanUp() {
    this._pendingLicences.next([]);
  }

  private groupPendingLicences(
    data: RawPendingStartingLicence[],
  ): RawPendingStartingLicence[] {
    const result = data.reduce(
      (
        accumulator: RawPendingStartingLicence,
        current: RawPendingStartingLicence,
      ) => {
        const { startingLicences, ...club } = current;
        if (accumulator[current.club.id]) {
          accumulator[current.club.id].startingLicences.push(startingLicences);
        } else {
          accumulator[current.club.id] = club;
          accumulator[current.club.id].startingLicences = startingLicences;
        }
        return accumulator;
      },
      {} as RawPendingStartingLicence,
    );
    return Object.keys(result).map((key) => result[key]);
  }
}
