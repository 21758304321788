import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { UsernameAvailableData } from "@app/core/auth/registration/UsernameAvailable";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserManagementResponse } from "@app/core/auth/registration/UserManagement";
import { ServerResponse } from "@app/core/types";
import {
  ActivateUserData,
  RegistrationData,
  ResetPasswordData,
  ChangePasswordData,
  EditPasswordData,
} from "./RegistrationData";

@Injectable()
export class RegistrationService {
  private userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  registerUser(registerUserData: RegistrationData): Observable<any> {
    return this.api.post("users", registerUserData);
  }

  activateUser(activateUserData: ActivateUserData): Observable<void> {
    const payload = {
      username: activateUserData.loginName,
      token: activateUserData.registrationToken,
    };
    return this.api.post("users/confirmation", payload);
  }

  editPassword(editPasswordData: EditPasswordData): Observable<ServerResponse> {
    return this.api.put(
      `users/${this.userProfile.uuid}/password`,
      editPasswordData,
    );
  }

  changePassword(changePasswordData: ChangePasswordData): Observable<void> {
    const payload = {
      newPassword: changePasswordData.newPassword,
      token: changePasswordData.resetPasswordToken,
      username: changePasswordData.loginName,
    };

    return this.api.post("users/password", payload);
  }

  requestPasswordReset(resetPasswordData: ResetPasswordData): Observable<void> {
    return this.api.post(`users/passwordReset/${resetPasswordData.loginName}`);
  }

  requestUserNameResend(data: {
    emailAddress: string;
  }): Observable<UserManagementResponse> {
    const param = new HttpParams().set("email", data.emailAddress);
    return this.api.post(`users/names?${param}`);
  }

  checkUserName(username: string): Observable<UsernameAvailableData> {
    return this.api
      .get("users/names", {
        username,
      })
      .pipe(
        map((data: any) => {
          return {
            available: data.available,
          };
        }),
      );
  }
}
