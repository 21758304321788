import { Injectable } from "@angular/core";
import { ServerResponse, PendingInvitation } from "@app/core/types";
import { ApiService } from "@app/shared/api.service";
import { Observable, BehaviorSubject } from "rxjs";
import { map, filter, share } from "rxjs/operators";

@Injectable()
export class MembershipService {
  private _pendingInvitations = new BehaviorSubject<PendingInvitation[]>(null);

  constructor(private api: ApiService) {}

  fetchPendingInvitations(): Observable<PendingInvitation[]> {
    const request = this.api
      .get("gymnasts/list/pendingInvitations/own")
      .pipe(share());
    request.subscribe((data) => {
      this._pendingInvitations.next(data);
    });
    return request;
  }

  get pendingInvitations() {
    return this._pendingInvitations.pipe(filter((data) => Boolean(data)));
  }

  confirmPendingInvitation(clubId: string): Observable<ServerResponse> {
    return this.api.post(`gymnasts/acceptPendingInvitation/${clubId}`, null);
  }

  refusePendingInvitation(clubId: string): Observable<ServerResponse> {
    return this.api.post(`gymnasts/refusePendingInvitation/${clubId}`, null);
  }

  cleanUp() {
    this._pendingInvitations.next(null);
  }
}
