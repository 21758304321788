import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";

@Injectable()
export class SearchStartingLicenceService {
  constructor(private api: ApiService) {}

  search(
    skip: number,
    limit: number,
    sort: { keyword: string; asc: boolean },
    keyword: string,
    disciplineId: string,
  ): Observable<any> {
    return this.api.get("list/all", {
      query: keyword,
      discipline: disciplineId,
      pageSize: limit,
      pageNumber: skip,
      sortDir: sort.asc ? "asc" : "desc",
      sortBy: sort.keyword,
    });
  }

  getFilteredList(
      skip: number,
      limit: number,
      sort: { keyword: string; asc: boolean },
      keyword: string,
      disciplineId: string,
      terms: Array<any> = [],
  ): Observable<any> {
    const params: any = {
      disciplineUuid: disciplineId,
      listRequest: {
        query: keyword,
        pageSize: limit,
        pageNumber: skip,
        sortDir: sort.asc ? "asc" : "desc",
        sortBy: sort.keyword,
      }
    };
    if(terms.length) {
      params.expression = {
        type: "compound",
        operator: "and",
        terms
      };
    }

    return this.api.post("list/all-license-filtered", params);
  }

}
