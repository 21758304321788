import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";
import {ServerResponse} from '@app/core/types';
import {ContactAdminExport} from '@app/core/user/contacts-admin/contact-admin-export';

@Injectable()
export class ContactsAdminService {

  filters = {
      contacts: [],
      licenses: [],
      stamps: [],
      openItems: [],
  };

  items = {
    contacts: [],
    licenses: [],
    stamps: [],
    openItems: [],
    competitions: [],
    openRegistrations: [],
  };

  sorts = {
    contacts: {
      keyword: 'lastName',
      asc: false,
    },
    licenses: {
      keyword: 'validUntil',
      asc: false,
    },
    stamps: {
      keyword: "validUntil",
      asc: false,
    },
    openItems: {
      keyword: "dueDate",
      asc: false,
    },
    competitions: {
      keyword: "eventdate",
      asc: false,
    },
    openRegistrations: {
      keyword: "createdAt",
      asc: false
    },
  };

  queries = {
    contacts: '',
    licenses: '',
    stamps: '',
    openItems: '',
    openRegistrations: '',
  };

  scrollbars = {
    contacts: 0,
    licenses: 0,
    stamps: 0,
    openItems: 0,
    competitions: 0,
    openRegistrations: 0,
  };

  constructor(private api: ApiService) {}

  getFilteredList(
    path: string,
    skip: number,
    limit: number,
    sort: { keyword: string; asc: boolean },
    keyword: string = null,
    terms: Array<any> = []
  ): Observable<any> {
    const params: any = {
        listRequest: {
            query: keyword,
            pageSize: limit,
            pageNumber: skip,
            sortDir: sort.asc ? "asc" : "desc",
            sortBy: sort.keyword,
        },
    };

    if (terms.length) {
      params.expression = {
          type: "compound",
          operator: "and",
          terms
      };
    }

    return this.api.post("contactsAdmin/" + path, params);
  }

  getLicense(id: string): Observable<any>  {
    return this.api.get("contactsAdmin/licenses/" + id);
  }

  getStamp(id: string): Observable<any>  {
    return this.api.get("contactsAdmin/stamps/" + id);
  }

  getOpenItem(id: string): Observable<any>  {
    return this.api.get("contactsAdmin/openItems/" + id);
  }

  /* CLUBS */

  getClub(clubId: string): Observable<any>  {
    return this.api.get("contactsAdmin/clubs/" + clubId);
  }

  getClubAdmins(clubId: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/admins/" + clubId, params);
  }

  getClubGymnasts(clubId: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/club/gymnasts/" + clubId, params);
  }

  getClubStamps(clubId: string, params: any): Observable<any>  {
    return this.api.get("stamps/" + clubId, params);
  }

  getClubLicenses(clubId: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/licenses/club/" + clubId, params);
  }

  getClubOpenItems(clubId: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/openItems/club/" + clubId, params);
  }

  getClubData(type: string, clubId: string, params: any): Observable<any> {
    let result: Observable<any>;

    switch (type) {
      case "admins":
          result = this.getClubAdmins(clubId, params);
          break;
      case "gymnasts":
          result = this.getClubGymnasts(clubId, params);
          break;
      case "stamps":
          result = this.getClubStamps(clubId, params);
          break;
      case "licenses":
          result = this.getClubLicenses(clubId, params);
          break;
      case "openItems":
          result = this.getClubOpenItems(clubId, params);
          break;
    }

    return result;
  }

  /* ORGANISATIONS */

  getOrganisation(orgId: string): Observable<any>  {
    return this.api.get("contactsAdmin/districtOrganization/" + orgId);
  }

  getOrgClubs(id: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/clubs/parent/" + id, params);
  }

  getOrgOrgs(id: string, params: any): Observable<any>  {
    return this.api.get("contactsAdmin/organizations/parent/" + id, params);
  }

  getOrgData(type: string, id: string, params: any): Observable<any> {
    let result: Observable<any>;

    switch (type) {
      case "clubs":
        result = this.getOrgClubs(id, params);
        break;

      case "orgs":
        result = this.getOrgOrgs(id, params);
        break;
    }

    return result;
  }

  /* PROFILE */

  getGymnast(userId: string): Observable<any> {
    return this.api.get("gymnasts/" + userId);
  }

  getGymnastLicenses(userId: string, params: any): Observable<any> {
    return this.api.get("contactsAdmin/licenses/gymnast/" + userId, params);
  }

  getGymnastStamps(userId: string, params: any): Observable<any> {
    return this.api.get("gymnasts/" + userId + "/stamps/", params);
  }

  getGymnastOpenItems(userId: string, params: any): Observable<any> {
    return this.api.get("/contactsAdmin/openItems/gymnast/" + userId, params);
  }

  getGymnastData(type: string, id: string, params: any): Observable<any> {
    let result: Observable<any>;

    switch (type) {
      case "licenses":
        result = this.getGymnastLicenses(id, params);
        break;

      case "stamps":
        result = this.getGymnastStamps(id, params);
        break;

      case "openItems":
        result = this.getGymnastOpenItems(id, params);
        break;
    }

    return result;
  }

  createGymnast(params: any): Observable<any> {
    return this.api.post("contactsAdmin/contact", params);
  }

  editGymnast(params: any): Observable<any> {
    return this.api.put("contactsAdmin/contact", params);
  }

  deleteGymnast(userId: String): Observable<any> {
    return this.api.delete("contactsAdmin/gymnast/" + userId);
  }

  deleteGymnasts(addressUuids: Array<String>): Observable<any> {
    return this.api.delete("contactsAdmin/gymnasts", {uuids: addressUuids});
  }

  changePassword(userId: string, newPassword: string): Observable<any> {
    return this.api.put(`contactsAdmin/gymnast/change-password`, { userId, newPassword });
  }

  changeEmail(userId: string, newEmail: string): Observable<any> {
    return this.api.put(`contactsAdmin/gymnast/change-email`, { userId, newEmail });
  }

  resendActivation(userId: string): Observable<any> {
    return this.api.get(`contactsAdmin/gymnast/${userId}/resend-activation`);
  }

  editStamp(params: any): Observable<any> {
    return this.api.put("contactsAdmin/stamp", params);
  }

  deleteStamps(uuids: Array<String>): Observable<any> {
    return this.api.delete("contactsAdmin/stamps", {uuids});
  }

  editLicense(params: any): Observable<any> {
    return this.api.put("contactsAdmin/license", params);
  }

  deleteLicenses(uuids: Array<String>): Observable<any> {
    return this.api.delete("contactsAdmin/licenses", {uuids});
  }

  getTimeStamp(): Observable<any> {
    return this.api.get("contactsAdmin/timeStamp");
  }

  getListCSV(data: ContactAdminExport): Observable<ServerResponse<string>> {
    return this.api.downloadWithBody("contactsAdmin/" + data.resourcesType + "/csv", {},data, "text/csv", "post");
  }

  getOpenRegistrations(params: any): Observable<any> {
    const request: any = {
      listRequest: {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        sortBy: params.sortBy,
        sortDir: params.sortDir,
        query: params.query,
      },
  };
    return this.api.post("contactsAdmin/openRegistrations", request);
  }
}