import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import {
  RouterModule,
  PreloadAllModules,
  UrlSerializer,
} from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NewAuthGuard, NewUnAuthGuard } from "@app/auth/guards/auth.guard";
import { STORE_MODULE } from "@app/core/store";
import { TokenInterceptor } from "@app/shared/interceptors/token.interceptor";
import { StartingLicenceDataSource } from "@app/views/user/components/starting-licence-for-gymnasts/StartingLicenceDataSource";

import { routes } from "./app.routes";
import { AppComponent } from "./app.component";
import { CoreModule } from "@app/core/core.module";
import { SharedModule } from "@app/shared/shared.module";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CustomUrlSerializer } from "@app/core/util/CustomUrlSerializer";
import { AutoScrollToTopDirective } from "./shared/util/auto-scroll-to-top.directive";
import { WebpackTranslateLoader } from "@app/core/translation/app.translate.loader";
import { WithdrawClubComponent } from "./views/user/component/withdraw-club/withdraw-club.component";
import { appConfig } from "./app.config";
import { ContactAdminDownloadComponent } from "./views/user/components/contact-admin-download/contact-admin-download.component";
import { DialogComponent } from "./views/user/components/dialog/dialog.component";

@NgModule({
  declarations: [AppComponent, AutoScrollToTopDirective, WithdrawClubComponent, ContactAdminDownloadComponent, DialogComponent],
  entryComponents: [WithdrawClubComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ...STORE_MODULE,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    CoreModule,
    SharedModule.forRoot(),
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    BrowserAnimationsModule,
  ],

  providers: [
    NewAuthGuard,
    NewUnAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => {
        translateService.setDefaultLang(appConfig.language);
        return () => translateService.use(appConfig.language).toPromise();
      },
      multi: true,
      deps: [TranslateService],
    },
    StartingLicenceDataSource,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
