import {
  Directive,
  AfterContentInit,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

@Directive({
  selector: "[appAutoScrollToTop]",
})
export class AutoScrollToTopDirective implements OnDestroy {
  private subs = new Subscription();

  constructor(private element: ElementRef, private router: Router) {
    const scrollToTopSub = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        (this.element.nativeElement as HTMLDivElement).scrollTop = 0;
      });
    this.subs.add(scrollToTopSub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
