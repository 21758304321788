import { Injectable } from "@angular/core";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
@Injectable()
export class SearchGymnastsService {
  private userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  search(
    skip: number,
    limit: number,
    sort: { keyword: string; asc: boolean },
    keyword: string,
  ): Observable<any> {
    return this.api.get(`clubs/${this.userProfile.clubId}/gymnasts`, {
      query: keyword,
      pageSize: limit,
      pageNumber: skip,
      sortDir: sort.asc ? "asc" : "desc",
      sortBy: sort.keyword,
    });
  }

  getFilteredList(
      skip: number,
      limit: number,
      sort: { keyword: string; asc: boolean },
      keyword: string,
      terms: Array<any> = []
  ): Observable<any> {
    const params: any = {
      listRequest: {
        query: keyword,
        pageSize: limit,
        pageNumber: skip,
        sortDir: sort.asc ? "asc" : "desc",
        sortBy: sort.keyword,
      }
    };
    if (terms.length) {
      params.expression = {
        type: "compound",
        operator: "and",
        terms
      };
    }
    return this.api.post(`clubs/${this.userProfile.clubId}/gymnasts-filtered`, params);
  }
}
