import { Injectable } from "@angular/core";
import { RxStomp } from "@stomp/rx-stomp";
import { environment } from "@env/environment";
import { IMessage } from "@stomp/stompjs/esm6/i-message";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class OpenActionCounterService {
    private ws = new RxStomp();
    private notificationCounter$ = new BehaviorSubject<number>(0);

    constructor() {}

    subscribeToHost(authToken: string) {
        this.ws.deactivate();
        this.ws.configure({
            brokerURL: environment.wsHostname + "?access_token=" + authToken,
            heartbeatIncoming: 20000,
            heartbeatOutgoing: 20000,
            reconnectDelay: 20000,
            // debug: (msg: string): void => {
            //     console.log(new Date().getTime(), msg);
            // }
        });
        this.ws.activate();
        this.ws.watch("/user/get-open-action-counter")
            .subscribe((value: IMessage) => this.notificationCounter$.next(Number(value.body)));
        this.refreshCounter();
    }

    refreshCounter() {
        this.ws.publish({destination: "/app/request-open-action-counter", body: "{}"});
    }

    getNotificationCounter(): BehaviorSubject<number> {
        return this.notificationCounter$;
    }
}