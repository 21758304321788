export class AuthActions {
  static readonly SIGN_IN = "[AUTH] SIGN_IN";
  static readonly SIGN_IN_SSO = "[AUTH] SIGN_IN_SSO";
  static readonly SIGN_IN_SUCCESS = "[AUTH] SIGN_IN_SUCCESS";
  static readonly SIGN_IN_ERROR = "[AUTH] SIGN_IN_ERROR";
  static readonly SIGN_OUT = "[AUTH] SIGN_OUT";
  static readonly SET_AUTH = "[AUTH] SET_AUTH";
  static readonly SET_TOKEN = "[AUTH] SET_TOKEN";
  static readonly REFRESH_TOKEN = "[AUTH] REFRESH_TOKEN";
  static readonly REFRESH_USER = "[AUTH] REFRESH_USER";
  static readonly REFRESH_USER_SUCCESS = "[AUTH] REFRESH_USER_SUCCESS";
  static readonly REFRESH_USER_ERROR = "[AUTH] REFRESH_USER_ERROR";
}
