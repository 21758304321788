import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  clubsFetched,
  FETCH_ASSOCIATED_CLUBS,
} from "@app/store/club/club.actions";
import { map, switchMap } from "rxjs/operators";
import { ClubService } from "@app/core/user";

@Injectable()
export class ClubEffects {
  constructor(private actions$: Actions, private clubService: ClubService) {}

  @Effect()
  fetchAssociatedClubs$ = this.actions$.pipe(
    ofType(FETCH_ASSOCIATED_CLUBS),
    switchMap(({ userId }) => this.clubService.getAssociatedClubs(userId)),
    map((clubs) => clubsFetched({ payload: clubs })),
  );
}
