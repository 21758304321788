import { NgModule } from "@angular/core";
import {
  FormProgressBarDirective,
  FormProgressCurtainDirective,
  FormProgressDisabledDirective,
  FormProgressSpinnerDirective,
} from "@app/shared/forms/progress/form-progress.directive";

@NgModule({
  declarations: [
    FormProgressBarDirective,
    FormProgressCurtainDirective,
    FormProgressDisabledDirective,
    FormProgressSpinnerDirective,
  ],
  exports: [
    FormProgressBarDirective,
    FormProgressCurtainDirective,
    FormProgressDisabledDirective,
    FormProgressSpinnerDirective,
  ],
})
export class ProgressModule {}
