import { createAction, props } from "@ngrx/store";
import { ClubData } from "@app/core/types/ClubData";

export const ASSOCIATED_CLUBS_FETCHED = "[CLUB] CLUBS_FETCHED";
export const FETCH_ASSOCIATED_CLUBS = "[CLUB] FETCH_ASSOCIATED_CLUBS";

export const clubsFetched = createAction(
  ASSOCIATED_CLUBS_FETCHED,
  props<{ payload: ClubData[] }>(),
);

export const fetchAssociatedClubs = createAction(
  FETCH_ASSOCIATED_CLUBS,
  props<{ userId: string }>(),
);
