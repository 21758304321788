import { Injectable } from "@angular/core";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import {
  ServerResponse,
  StartingLicenceData,
  StartingLicencesResponse,
} from "@app/core/types";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, share, filter } from "rxjs/operators";

@Injectable()
export class StartingLicenceService {
  private _licencesForForeignClubs = new BehaviorSubject<StartingLicenceData[]>(
    [],
  );
  private _licencesForMyClub = new BehaviorSubject<StartingLicenceData[]>([]);
  private _licencesForGymnast = new BehaviorSubject<StartingLicenceData[]>([]);

  private userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  applyForLicencesTakeOver(licenceIds: string[]): Observable<unknown> {
    return this.api.post(`overtake/licences/release/ban`, {
      licenceUuids: licenceIds,
    });
  }

  approveLicencesTakeOver(licenceIds: string[]): Observable<unknown> {
    return this.api.post("overtake/licences/release/ban/approve", {
      licenceUuids: licenceIds,
    });
  }

  rejectLicencesTakeOver(licenceIds: string[]): Observable<unknown> {
    return this.api.post("overtake/licences/release/ban/reject", {
      licenceUuids: licenceIds,
    });
  }

  withdrawLicence(
    licenceId: string,
  ): Observable<any> {
    return this.api.patch("/licence/withdraw", {
      licenceId
    });
  }

  getLicencesToRelease(
    gymnastId: string,
    formerClubId: string,
  ): Observable<StartingLicencesResponse> {
    return this.api.get(
      `gymnasts/${gymnastId}/${formerClubId}/licences/to/release`,
    );
  }

  getLicencesForForeignClubs(userId: string) {
    const request = this.api.get(`gymnasts/${userId}/licences/foreign`).pipe(
      map((response: any) => {
        return response.content;
      }),
      share(),
    );
    request.subscribe((data) => {
      this._licencesForForeignClubs.next(data);
    });
    return request;
  }

  getLicencesForClubAdminsClub(userId: string) {
    const request = this.api.get(`gymnasts/${userId}/licences`).pipe(
      map((response: any) => {
        return response.content;
      }),
      share(),
    );
    request.subscribe((data) => {
      this._licencesForMyClub.next(data);
    });
    return request;
  }

  refreshLicencesForGymnast() {
    const request = this.api.get(`list/own`).pipe(
      map((response: any) => {
        return response.content;
      }),
      share(),
    );
    request.subscribe((data) => {
      this._licencesForGymnast.next(data);
    });
    return request;
  }

  public withdrawRequest(userId: string): Observable<ServerResponse> {
    return this.api.post(`withdraw/for/${userId}`, {});
  }

  public get firstFiveLicencesForForeignClubs(): Observable<
    StartingLicenceData[]
  > {
    return this._licencesForForeignClubs.pipe(
      map((data) =>
        data
          .sort((licenceA, licenceB) =>
            this.sortAlphabetically(licenceA, licenceB),
          )
          .slice(0, 5),
      ),
    );
  }

  public get firstFiveLicencesForMyClub(): Observable<StartingLicenceData[]> {
    return this._licencesForMyClub.pipe(
      map((data) =>
        data
          .sort((licenceA, licenceB) =>
            this.sortAlphabetically(licenceA, licenceB),
          )
          .slice(0, 5),
      ),
    );
  }

  public get firstFiveLicencesForGymnast(): Observable<StartingLicenceData[]> {
    return this._licencesForGymnast.pipe(
      map((data) =>
        data
          .sort((licenceA, licenceB) =>
            this.sortAlphabetically(licenceA, licenceB),
          )
          .slice(0, 5),
      ),
    );
  }

  public get licencesForGymnast(): Observable<StartingLicenceData[]> {
    return this._licencesForGymnast.asObservable();
  }

  public get licencesForForeignClubs(): Observable<StartingLicenceData[]> {
    return this._licencesForForeignClubs.asObservable();
  }

  public get licencesForMyClub(): Observable<StartingLicenceData[]> {
    return this._licencesForMyClub.asObservable();
  }

  private sortAlphabetically(
    licenceA: StartingLicenceData,
    licenceB: StartingLicenceData,
  ): number {
    if (!licenceA.discipline || !licenceB.discipline) {
      return -1;
    }
    return licenceA.discipline.localeCompare(licenceB.discipline);
  }

  cleanUp() {
    this._licencesForForeignClubs.next([]);
    this._licencesForMyClub.next([]);
    this._licencesForGymnast.next([]);
  }
}
