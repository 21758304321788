import { EffectsModule } from "@ngrx/effects";
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { localStorageSync } from "ngrx-store-localstorage";
import { environment } from "@env/environment";
import { AppState } from "@app/app.state";
import { AuthEffects } from "@app/store/auth/auth.effect";
import { authReducer as auth } from "@app/store/auth/auth.reducer";
import { coreReducer as core } from "@app/store/core/core.reducers";
import { CoreEffects } from "@app/store/core/core.effects";
import { ClubEffects } from "@app/store/club/club.effect";

const effects = [AuthEffects, ClubEffects, CoreEffects];

export const reducers: ActionReducerMap<AppState> = { auth, core };

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({ keys: ["auth", "core"], rehydrate: true, storage: sessionStorage })(reducer);
}

export function logger(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return (state: AppState, action: any): AppState => {
    // if (!action.type.includes('@ngrx')) {
    console.log(`%c${action.type}`, "color:green", action.payload);
    // }
    return reducer(state, action);
  };
}

const metaReducers: Array<MetaReducer<AppState>> = !environment.production
  ? [localStorageSyncReducer, logger]
  : [localStorageSyncReducer];

export const STORE_MODULE = [
  StoreModule.forRoot(reducers, { metaReducers }),
  EffectsModule.forRoot(effects),
  StoreDevtoolsModule.instrument(),
];
