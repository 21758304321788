import { UrlTree, DefaultUrlSerializer } from "@angular/router";

export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    url = url.replace(/\+/g, "%20");
    return super.parse(url);
  }

  serialize(tree: UrlTree): string {
    return super.serialize(tree).replace(/%20/g, "+");
  }
}
