import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.state";
import { AuthState } from "@app/store/auth/auth.state";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AuthUserDenyGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const id = next.params["id"];

    return this.store.select("auth").pipe(
      map((auth: AuthState) => {
        const test = !!(
          auth != null &&
          auth !== undefined &&
          auth.accessToken &&
          auth.user
        );
        const test2 = id === auth.user.uuid;

        if (!test || !test2) {
          this.router.navigateByUrl("error/404", { skipLocationChange: true });
        }
        return test;
      }),
    );
  }
}
