<header>
  <div
    [style.visibility]="(isLogged$ | async) ? 'visible' : 'hidden'"
    class="menu-wrapper"
  >
    <div class="menu" (click)="toggleNavigation()">
      <mat-icon>menu</mat-icon> <span>{{ "NAVIGATION_MENU" | translate }}</span>
    </div>
  </div>
  <div class="logo">
    <img src="assets/img/turnportal.svg" [routerLink]="'/'" />
    <div class="logo-footer" [routerLink]="'/'" *ngIf="isLogoFooterVisible()">
      {{ "LOGO_FOOTER_TEXT" | translate }}
    </div>
  </div>
  <div class="notification-wrapper" *ngIf="isClubAdmin || isGymnast">
    <i class="notification" [routerLink]="'/open-actions'">
      <div class="notification-count" *ngIf="notificationCounter$ | async "  >
        {{ notificationCounter$ | async }}</div>
        <mat-icon>notifications</mat-icon>
    </i>
  </div>
  <div class="faq-wrapper">
    <a class="faq" [href]="faqLink" target="_blank">
      <mat-icon>help_outline</mat-icon>
    </a>
  </div>
</header>

<div
  class="navigation-overlay"
  *ngIf="showNavigation"
  (click)="closeNavigation()"
></div>

<nav class="navigation" [class.active]="showNavigation">
  <div>
    <a
      *ngIf="userProfile.isGymnast"
      [routerLink]="userProfile.profileUrl"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>person</mat-icon> {{ "NAVIGATION_MY_PROFILE" | translate }}
    </a>

    <a
      *ngIf="userProfile.isClubAdmin"
      routerLink="/show-gymnasts"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>people</mat-icon> {{ "NAVIGATION_ALL_GYMNASTS" | translate }}
    </a>

    <a
      *ngIf="userProfile.isClubAdmin"
      routerLink="/starting-licences"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>people</mat-icon> {{ "NAVIGATION_STARTING_LICENSES_CHECK" | translate }}
    </a>

    <a
      *ngIf="userProfile.isClubAdmin || userProfile.isGymnast"
      routerLink="/open-actions"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>check_box</mat-icon> {{ "NAVIGATION_SHOW_OPEN_ACTIONS" | translate }}
    </a>

    <a
            *ngIf="userProfile.isCompetitionOrganizer"
            routerLink="/competition-list"
            (click)="closeNavigation()"
            routerLinkActive="active"
    >
      <mat-icon>people</mat-icon> {{ "NAVIGATION_STARTING_LICENSES_CHECK" | translate }}
    </a>

    <!-- CONTACT ADMIN -->

    <a
      *ngIf="userProfile.isContactAdmin"
      routerLink="/contacts"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>person</mat-icon>
      {{ "NAVIGATION_CONTACTS" | translate }}
    </a>

    <a
      *ngIf="userProfile.isContactAdmin"
      routerLink="/licenses"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>assignment_ind</mat-icon>
      {{ "NAVIGATION_LICENSES" | translate }}
    </a>

    <a
      *ngIf="userProfile.isContactAdmin"
      routerLink="/annual-stamps"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>vignette</mat-icon>
      {{ "NAVIGATION_ANNUAL_STAMPS" | translate }}
    </a>

    <a
      *ngIf="userProfile.isContactAdmin"
      routerLink="/open-items"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>monetization_on</mat-icon>
      {{ "NAVIGATION_OPEN_ITEMS" | translate }}
    </a>

    <a
      *ngIf="userProfile.isContactAdmin"
      routerLink="/open-registrations"
      (click)="closeNavigation()"
      routerLinkActive="active"
    >
      <mat-icon>accessibility_new</mat-icon>
      {{ "NAVIGATION_OPEN_REGISTRATIONS" | translate }}
    </a>

    <a *ngIf="isLogged$ | async" (click)="logout()" class="last">{{
      "NAVIGATION_LOGOUT" | translate
    }}</a>

    <div class="club-info" *ngIf="isClubAdmin && club">
      <div><strong>{{ "PROFILE_LOGGED_INTO" | translate }}</strong></div>
      <div>{{ club.name }}</div>
    </div>
  </div>
</nav>
