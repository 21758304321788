import { AuthActions } from "../auth/auth.actions";
import { CoreActions } from "./core.actions";
import { CoreState } from "./core.state";

export function coreReducer(state: CoreState = new CoreState(), action: any) {
  switch (action.type) {
    case CoreActions.HIDE_WELCOME_SECTION:
      return { ...state, welcomeSectionDismissed: true };
    case CoreActions.INIT:
      return { ...state, ...{ isLoading: false } };
    case AuthActions.SIGN_IN:
      return { ...state, ...{ isLoading: true } };
    case CoreActions.SET_OFFLINE:
      return { ...state, isOnline: false };
    case CoreActions.SET_ONLINE:
      return { ...state, isOnline: true };
    case CoreActions.LOADING:
      return { ...state, isLoading: true };
    case CoreActions.LOADED:
      return { ...state, isLoading: false };
    case CoreActions.SET_LANGUAGE:
      const language = action.payload;
      return { ...state, language };
    case AuthActions.SIGN_IN_SUCCESS:
      return { ...state, ...{ welcomeSectionDismissed: false } };
    case AuthActions.SIGN_OUT:
      return {
        ...state,
        ...{
          isLoading: false,
          sideNavOpened: false,
          welcomeSectionDismissed: false,
        },
      };
    case CoreActions.SET_TITLE:
      return { ...state, ...action.payload };
    case CoreActions.SIDE_NAV_TOGGLE:
      return { ...state, ...{ sideNavOpened: !state.sideNavOpened } };
    case CoreActions.SET_REDIRECT_URL:
      return { ...state, ...{ redirectUrl: action.payload.redirectUrl } };
    case CoreActions.REDIRECT_URL_RESET:
      return { ...state, ...{ redirectUrl: null } };
    default:
      return state;
  }
}
