import { Observable } from "rxjs";
import { ServerResponse } from "@app/core/types";
import { PurchaseService } from "../purchase/purchase.service";
import { BankAccount } from "../purchase/bank-account";

export abstract class BuyStartingLicenceService {
  constructor(protected purchaseService: PurchaseService) {}

  getBankAccounts(clubId: string = null): Observable<BankAccount[]> {
    return this.purchaseService.getBankAccounts(clubId);
  }
  purchaseForMultiple(data: any): Observable<any> {
    throw new Error("Method not implemented.");
  }
  calculateForMultiple(data: any) : Observable<any> {
    throw new Error("Method not implemented.");
  }
  abstract calculate(data: any): Observable<any>;
  abstract purchase(data: any): Observable<any>;
  abstract save(data: any): Observable<any>;
}
