export interface AnnualStamp {
  /**
   * @property price
   * @description price of the annual stamp
   */
  price: number;

  /**
   * @property type
   * @description type of the annual stamp
   */
  type: AnnualStampType;

  /**
   * @property upgrade
   * @description true when there is already another annual stamp
   */
  upgrade?: boolean;

  /**
   * @property count
   * @description number of annual stamps which will be ordered of this type
   */
  count?: number;
}

export enum AnnualStampType {
  Child = "SINGLECHILD",
  Single = "SINGLEADULT",
  Team = "TEAM",
}
