import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { HeaderComponent } from "./header/header.component";
import { SearchFieldComponent } from "./search-field/search-field.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { SimpleNotificationDialogComponent } from "./simple-notification-dialog/simple-notification-dialog.component";
import { FiltersDialogComponent } from "./filters-dialog/filters-dialog.component";
import { GetClubDialogComponent } from "./get-club-dialog/get-club-dialog.component";
import { GetStampDialogComponent } from "./get-stamp-dialog/get-stamp-dialog.component";
import { GetLicenseDialogComponent } from "./get-license-dialog/get-license-dialog.component";
import { GetOpenItemDialogComponent } from "./get-open-item-dialog/get-open-item-dialog.component";
import { GetDisciplineDialogComponent } from "./get-discipline-dialog/get-discipline-dialog.component";
import { CreateEditGymnastDialogComponent } from "./create-edit-gymnast-dialog/create-edit-gymnast-dialog.component";
import { PopupComponent } from "./popup/popup.component";
import { AutoFocusFieldDirective } from "./util/auto-focus-field.directive";
import { RouterModule } from "@angular/router";
import { DisplayImageComponent } from "./display-image/display-image.component";
import localeDe from "@angular/common/locales/de";
import { FooterComponent } from "./footer/footer.component";
import { ProgressModule } from "@app/shared/forms/progress/form-progress.module";
import { UploadComponent } from "./upload/upload.component";
import { ClassIfEmpty } from "@app/shared/util/class-if-empty.directive";
import { AutocompleteSearchComponent } from "./autocomplete-search/autocomplete-search.component";
import { MultiLineTooltipDirective } from "./util/multi-line-tooltip.directive";
import { GenderPipe } from "./util/gender.pipe";
import { ClickSelectionLinkDirective } from "@app/shared/util/click-selection-link.directive";
import { TrimOnBlurDirective } from "./util/trim-on-blur.directive";
import { DisciplineCheckboxComponent } from "./get-discipline-dialog/discipline-checkbox/discipline-checkbox.component";
import { EditStampDialogComponent } from "./edit-stamp-dialog/edit-stamp-dialog.component";
import { EditLicenseDialogComponent } from './edit-license-dialog/edit-license-dialog.component';
import { GetDisciplineDialogLicenseComponent } from './get-discipline-dialog-license/get-discipline-dialog-license.component';
import { DeleteGymnastDialogComponent } from "./delete-gymnast-dialog/delete-gymnast-dialog.component";
import { DeleteGymnastsDialogComponent } from "./delete-gymnasts-dialog/delete-gymnasts-dialog.component";
import { WithdrawLicenceDialogComponent } from "./withdraw-license/withdraw-license-dialog.component";
import { ResendActivationComponent } from "@app/views/user/components/open-registrations/resend-activation/resend-activation.component";
import { AssignInactiveClubDialogComponent } from "@app/views/user/components/assign-club/assign-inactive-club-dialog/assign-inactive-club-dialog.component";
import { AccountInformationComponent } from "@app/views/user/components/account-information/account-information.component";
import { EditBankAccountComponent } from "@app/views/user/components/account-information/edit-bank-account/edit-bank-account.component";
import { EditOpenItemComponent } from "@app/views/user/components/open-item/edit-open-item/edit-open-item.component";

registerLocaleData(localeDe);
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    ProgressModule,
  ],
  declarations: [
    HeaderComponent,
    SearchFieldComponent,
    ImageUploadComponent,
    SimpleNotificationDialogComponent,
    PopupComponent,
    AutoFocusFieldDirective,
    ClickSelectionLinkDirective,
    DisplayImageComponent,
    FooterComponent,
    UploadComponent,
    ClassIfEmpty,
    AutocompleteSearchComponent,
    MultiLineTooltipDirective,
    GenderPipe,
    TrimOnBlurDirective,
    FiltersDialogComponent,
    GetClubDialogComponent,
    GetStampDialogComponent,
    GetLicenseDialogComponent,
    GetOpenItemDialogComponent,
    GetDisciplineDialogComponent,
    CreateEditGymnastDialogComponent,
    DisciplineCheckboxComponent,
    EditStampDialogComponent,
    EditLicenseDialogComponent,
    GetDisciplineDialogLicenseComponent,
    DeleteGymnastDialogComponent,
    DeleteGymnastsDialogComponent,
    WithdrawLicenceDialogComponent,
    ResendActivationComponent,
    AssignInactiveClubDialogComponent,
    EditBankAccountComponent,
    EditOpenItemComponent,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSnackBarModule,
    HeaderComponent,
    MatIconModule,
    SearchFieldComponent,
    ImageUploadComponent,
    PopupComponent,
    AutoFocusFieldDirective,
    DisplayImageComponent,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatListModule,
    FooterComponent,
    ProgressModule,
    UploadComponent,
    ClickSelectionLinkDirective,
    ClassIfEmpty,
    AutocompleteSearchComponent,
    MultiLineTooltipDirective,
    GenderPipe,
    TrimOnBlurDirective,
  ],
  entryComponents: [],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
        { provide: LOCALE_ID, useValue: "de-DE" },
      ],
    };
  }
}
