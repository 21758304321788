import { Injectable } from "@angular/core";
import { ApiService } from "@app/shared/api.service";
import { Observable } from "rxjs";

@Injectable()
export class ContactDtbAdminService {

  constructor(private api: ApiService) {}

  editBankAccount(uuid: string, params: any): Observable<any> {
    return this.api.patch(`contactDtbAdmin/bankAccount/${uuid}`, params);
  }

  editOpenItem(uuid: string, params: any): Observable<any> {
    return this.api.patch(`contactDtbAdmin/openItem/${uuid}`, params);
  }
}