import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { environment } from "@env/environment";
import { mergeMap, catchError } from "rxjs/operators";
import { ServerResponse } from "@app/core/types";
import { ImageUtils } from "@app/core/user/user-image/ImageUtils";

@Injectable()
export class UserImageService {
  userProfile: Profile;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  public saveImageForGymnast(
    image: File,
    guid: string,
  ): Observable<ServerResponse<void>> {
    const data = new FormData();
    data.append("image", image, image.name);
    return this.http.post<ServerResponse<void>>(
      this.getGymnastImageUrl(guid),
      data,
    );
  }

  public getImageForAddress(guid: string): Observable<string> {
    const urlPrefix = this.userProfile.isCompetitionOrganizer
      ? this.getParticipantImageUrl(guid)
      : this.getGymnastImageUrl(guid);
    return this.http.get(`${urlPrefix}/${guid}`, { responseType: "blob" }).pipe(
      catchError((e) => {
        return of(null);
      }),
      mergeMap((data) => {
        return data ? ImageUtils.getBase64Url(data) : of("");
      }),
    );
  }

  public getParticipantProfileImageUrl(guid: string): Observable<string> {
    // const urlPrefix = this.userProfile.isCompetitionOrganizer
    //   ? this.getParticipantImageUrl(guid)
    //   : this.getGymnastImageUrl(guid);
    const urlPrefix = this.getParticipantImageUrl(guid);
    return this.http.get(`${urlPrefix}/${guid}`, { responseType: "text" });
  }

  public getImageForUrl(imageUrl: string): Observable<string> {
    return this.http
      .get(imageUrl, { responseType: "blob" })
      .pipe(mergeMap((data) => ImageUtils.getBase64Url(data)));
  }

  public deleteImage(guid: string): Observable<ServerResponse<void>> {
    return this.http.delete<ServerResponse<void>>(
      this.getGymnastImageUrl(guid),
    );
  }

  private getGymnastImageUrl(gymnastId: string): string {
    return `${environment.apiHostname}/gymnasts/${gymnastId}/image`;
  }

  private getParticipantImageUrl(gymnastId: string): string {
    return `${environment.apiHostname}/gymnasts/${gymnastId}/participant/image`;
  }
}
