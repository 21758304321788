export class FileTypeError {}
export class FileSizeError {}
export enum BackendError {
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",

  NOT_SPECIFIED = "NOT_SPECIFIED",

  AUTHORIZATION = "AUTHORIZATION",
  NOT_ALLOWED = "NOT_ALLOWED",
  USER_NOT_ACTIVE = "USER_NOT_ACTIVE",
  USER_NOT_CONFIRMED = "USER_NOT_CONFIRMED",

  VALIDATION = "VALIDATION",
  VALIDATION_SIZE_STRING = "VALIDATION_SIZE_STRING",
  VALIDATION_MIN_VALUE = "VALIDATION_MIN_VALUE",
  VALIDATION_MAX_VALUE = "VALIDATION_MAX_VALUE",
  VALIDATION_EMAIL = "VALIDATION_EMAIL",

  NOT_FOUND = "NOT_FOUND",

  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  INVALID_TOKEN = "INVALID_TOKEN",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  TOKEN_USED = "TOKEN_USED",
  USER_ALREADY_ACTIVATED = "USER_ALREADY_ACTIVATED",
  CANNOT_REMOVE_FROM_CLUB = "CANNOT_REMOVE_FROM_CLUB",
  COULD_NOT_REGISTER_DTB_ID_APPLICATION = "COULD_NOT_REGISTER_DTB_ID_APPLICATION",
  GYMNAST_IS_ALREADY_IN_THIS_CLUB = "GYMNAST_IS_ALREADY_IN_THIS_CLUB",
  COULD_NOT_GENERATE_BYTES_FROM_PDF = "COULD_NOT_GENERATE_BYTES_FROM_PDF",
  COULD_NOT_GENERATE_BYTES_FROM_CSV = "COULD_NOT_GENERATE_BYTES_FROM_CSV",

  MEMBERSHIP_FETCH_PENDING_INVITATIONS_REQUEST_FAILED = "MEMBERSHIP_FETCH_PENDING_INVITATIONS_REQUEST_FAILED",
  MEMBERSHIP_CONFIRM_PENDING_INVITATION_REQUEST_FAILED = "MEMBERSHIP_CONFIRM_PENDING_INVITATION_REQUEST_FAILED",
  MEMBERSHIP_REFUSE_PENDING_INVITATION_REQUEST_FAILED = "MEMBERSHIP_REFUSE_PENDING_INVITATION_REQUEST_FAILED",
  GYMNAST_REMOVE_REGISTERED_REQUEST_FAILED = "GYMNAST_REMOVE_REGISTERED_REQUEST_FAILED",
  GYMNAST_GET_CURRENT_ANNUAL_STAMP_VALIDITY_REQUEST_FAILED = "GYMNAST_GET_CURRENT_ANNUAL_STAMP_VALIDITY_REQUEST_FAILED",
  GYMNAST_GET_FUTURE_ANNUAL_STAMP_VALIDITY_REQUEST_FAILED = "GYMNAST_GET_FUTURE_ANNUAL_STAMP_VALIDITY_REQUEST_FAILED",
  USER_IMAGE_SAVE_IMAGE_FOR_GYMNAST_REQUEST_FAILED = "USER_IMAGE_SAVE_IMAGE_FOR_GYMNAST_REQUEST_FAILED",
  USER_IMAGE_GET_IMAGE_REQUEST_FAILED = "USER_IMAGE_GET_IMAGE_REQUEST_FAILED",
  USER_IMAGE_GET_PARTICIPANT_IMAGE_REQUEST_FAILED = "USER_IMAGE_GET_PARTICIPANT_IMAGE_REQUEST_FAILED",
  USER_IMAGE_DELETE_IMAGE_REQUEST_FAILED = "USER_IMAGE_DELETE_IMAGE_REQUEST_FAILED",
  COMPETITION_GET_COMPETITION_REQUEST_FAILED = "COMPETITION_GET_COMPETITION_REQUEST_FAILED",
  COMPETITION_GET_P_D_F_REQUEST_FAILED = "COMPETITION_GET_P_D_F_REQUEST_FAILED",
  COMPETITION_GET_C_S_V_REQUEST_FAILED = "COMPETITION_GET_C_S_V_REQUEST_FAILED",
  COMPETITION_GET_PARTICIPANTS_REQUEST_FAILED = "COMPETITION_GET_PARTICIPANTS_REQUEST_FAILED",
  COMPETITION_GET_LIST_REQUEST_FAILED = "COMPETITION_GET_LIST_REQUEST_FAILED",
  COMPETITION_CREATE_COMPETITION_REQUEST_FAILED = "COMPETITION_CREATE_COMPETITION_REQUEST_FAILED",
  COMPETITION_DELETE_COMPETITION_REQUEST_FAILED = "COMPETITION_DELETE_COMPETITION_REQUEST_FAILED",
  PENDING_LICENCE_CONFIRM_STARTING_LICENCES_REQUEST_FAILED = "PENDING_LICENCE_CONFIRM_STARTING_LICENCES_REQUEST_FAILED",
  PENDING_LICENCE_REFUSE_STARTING_LICENCES_REQUEST_FAILED = "PENDING_LICENCE_REFUSE_STARTING_LICENCES_REQUEST_FAILED",
  STARTING_LICENCE_DETAILS_GET_COMPETING_STARTING_LICENCE_DETAILS_REQUEST_FAILED = "STARTING_LICENCE_DETAILS_GET_COMPETING_STARTING_LICENCE_DETAILS_REQUEST_FAILED",
  STARTING_LICENCE_REFRESH_LICENCES_FOR_GYMNAST_REQUEST_FAILED = "STARTING_LICENCE_REFRESH_LICENCES_FOR_GYMNAST_REQUEST_FAILED",
  STARTING_LICENCE_WITHDRAW_REQUEST_REQUEST_FAILED = "STARTING_LICENCE_WITHDRAW_REQUEST_REQUEST_FAILED",
  LICENCE_GET_ALL_DISCIPLINES_REQUEST_FAILED = "LICENCE_GET_ALL_DISCIPLINES_REQUEST_FAILED",
  GET_LICENCES_FOR_DISCIPLINES_FAILED = "GET_LICENCES_FOR_DISCIPLINES_FAILED",
  LICENCE_SAVE_STARTING_LICENCES_REQUEST_FAILED = "LICENCE_SAVE_STARTING_LICENCES_REQUEST_FAILED",
  LICENCE_SAVE_FOREIGN_STARTING_LICENCES_REQUEST_FAILED = "LICENCE_SAVE_FOREIGN_STARTING_LICENCES_REQUEST_FAILED",
  LICENCE_SAVE_EXTEND_STARTING_LICENCES_REQUEST_FAILED = "LICENCE_SAVE_EXTEND_STARTING_LICENCES_REQUEST_FAILED",
  LICENCE_SAVE_EXTEND_STARTING_LICENCES_MULTIPLE_REQUEST_FAILED = "LICENCE_SAVE_EXTEND_STARTING_LICENCES_MULTIPLE_REQUEST_FAILED",
  LICENCE_ADD_NOTE_REQUEST_FAILED = "LICENCE_ADD_NOTE_REQUEST_FAILED",
  LICENCE_GET_AVAILABLE_STARTING_LICENCES_REQUEST_FAILED = "LICENCE_GET_AVAILABLE_STARTING_LICENCES_REQUEST_FAILED",
  LICENCE_GET_STARTING_LICENCES_CONFLICTS_REQUEST_FAILED = "LICENCE_GET_STARTING_LICENCES_CONFLICTS_REQUEST_FAILED",
  PURCHASE_CALCULATE_ANNUAL_STAMP_REQUEST_FAILED = "PURCHASE_CALCULATE_ANNUAL_STAMP_REQUEST_FAILED",
  CALCULATE_FOREIGN_ANNUAL_STAMP_REQUEST_FAILED = "CALCULATE_FOREIGN_ANNUAL_STAMP_REQUEST_FAILED",
  CALCULATE_EXTEND_ANNUAL_STAMP_REQUEST_FAILED = "CALCULATE_EXTEND_ANNUAL_STAMP_REQUEST_FAILED",
  PURCHASE_CALCULATE_EXTEND_MULTIPLE_ANNUAL_STAMPS_REQUEST_FAILED = "PURCHASE_CALCULATE_EXTEND_MULTIPLE_ANNUAL_STAMPS_REQUEST_FAILED",
  PURCHASE_PURCHASE_ANNUAL_STAMP_REQUEST_FAILED = "PURCHASE_PURCHASE_ANNUAL_STAMP_REQUEST_FAILED",
  PURCHASE_PURCHASE_FOREIGN_ANNUAL_STAMP_REQUEST_FAILED = "PURCHASE_PURCHASE_FOREIGN_ANNUAL_STAMP_REQUEST_FAILED",
  PURCHASE_PURCHASE_EXTEND_ANNUAL_STAMP_REQUEST_FAILED = "PURCHASE_PURCHASE_EXTEND_ANNUAL_STAMP_REQUEST_FAILED",
  PURCHASE_PURCHASE_EXTEND_MULTIPLE_ANNUAL_STAMPS_REQUEST_FAILED = "PURCHASE_PURCHASE_EXTEND_MULTIPLE_ANNUAL_STAMPS_REQUEST_FAILED",
  PURCHASE_GET_BANK_ACCOUNTS_REQUEST_FAILED = "PURCHASE_GET_BANK_ACCOUNTS_REQUEST_FAILED",
  COULD_NOT_UPLOAD_IMAGE = "COULD_NOT_UPLOAD_IMAGE",
  COULD_NOT_DELETE_IMAGE = "COULD_NOT_DELETE_IMAGE",

  GET_OPEN_ITEMS_REQUEST_FAILED = "GET_OPEN_ITEMS_REQUEST_FAILED",
  GET_OPEN_ITEM_REQUEST_FAILED = "GET_OPEN_ITEM_REQUEST_FAILED",
  UPDATE_OPEN_ITEM_STATUS_REQUEST_FAILED = "UPDATE_OPEN_ITEM_STATUS_REQUEST_FAILED",
  STARTING_RIGHTS_REQUEST_FAILED = "STARTING_RIGHTS_REQUEST_FAILED",
  CLUB_NOT_ACTIVE = "CLUB_NOT_ACTIVE",
}
