import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "@app/shared/api.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map } from "rxjs/internal/operators";
import { environment } from "@env/environment";
import { AyService } from "../../ay.service";
import { CoreActions } from "./core.actions";

@Injectable()
export class CoreEffects {
  constructor(
    private ay: AyService,
    private actions$: Actions,
    private api: ApiService,
    private titleService: Title,
  ) {}

  @Effect({ dispatch: false })
  titleChange$ = this.actions$.pipe(
    ofType(CoreActions.SET_TITLE),
    map((action: any) => {
      if (action.payload.title) {
        const titlePrefix = environment.production ? " " : "[TEST]";
        const titleSuffix = action.payload.translate
          ? this.ay.i18n(action.payload.title)
          : action.payload.title;
        const title = this.getTitle(titleSuffix);
        this.titleService.setTitle(`${titlePrefix} ${title}`);
      }
    }),
  );

  private getTitle(titleSuffix: string): string {
    return this.ay.i18n("core.app.name");
  }
}
