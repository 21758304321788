import { createSelector } from "@ngrx/store";
import { AppState } from "@app/app.state";
import { CoreState } from "./core.state";

const coreObj = (_appState: AppState) => _appState.core;

export const isOnline = createSelector(
  coreObj,
  (store: CoreState) => store.isOnline,
);
export const isLoading = createSelector(
  coreObj,
  (store: CoreState) => store.isLoading,
);
export const getLanguage = createSelector(
  coreObj,
  (store: CoreState) => store.language,
);
export const getTitle = createSelector(coreObj, (state: CoreState) =>
  state.title ? state.title : "",
);
export const getBack = createSelector(
  coreObj,
  (state: CoreState) => state.back,
);
export const getRedirectUrl = createSelector(
  coreObj,
  (state: CoreState) => state.redirectUrl,
);
