import { Injectable } from "@angular/core";
import { AppState } from "@app/app.state";
import { getUser } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { ApiService } from "@app/shared/api.service";
import { Store } from "@ngrx/store";
import { map, catchError } from "rxjs/operators";
import { Observable, of, BehaviorSubject, forkJoin } from "rxjs";
import {
  GymnastData,
  ServerResponse,
  PartialGymnastData,
} from "@app/core/types";
import { DateUtils } from "@app/shared/util/DateUtils";
import { AnnualStamps } from "@app/core/user/gymnast/annual-stamp-validity";
import {
  isNullOrUndefined,
  isSuccessAndPayloadExist,
} from "@app/core/util/check-functions";

@Injectable()
export class GymnastService {
  private _existingInvitation = new BehaviorSubject<Date>(null);
  private _annualStampValidity = new BehaviorSubject<AnnualStamps>(null);
  private userProfile: Profile;

  constructor(private api: ApiService, private store: Store<AppState>) {
    this.store
      .select(getUser)
      .subscribe((userProfile) => (this.userProfile = userProfile));
  }

  saveMasterData(data: GymnastData): Observable<GymnastData> {
    return this.api.put(`gymnasts/${data.userId}`, data).pipe(
      map((response) => {
        response.dateOfBirth = DateUtils.convertServerStringToDate(
          response.dateOfBirth,
        );
        return response;
      }),
    );
  }

  create(data: GymnastData): Observable<GymnastData> {
    return this.api.post(`clubs/${this.userProfile.clubId}/gymnast`, data).pipe(
      map((response) => {
        response.dateOfBirth = DateUtils.convertServerStringToDate(
          response.dateOfBirth,
        );
        return response;
      }),
    );
  }

  removeRegistered(userId: string): Observable<any> {
    return this.api.delete(
      `clubs/${this.userProfile.clubId}/gymnasts/${userId}`,
    );
  }

  removeUnregistered(userId: string): Observable<any> {
    return this.api.delete(
      `clubs/${this.userProfile.clubId}/gymnasts/${userId}`,
    );
  }

  getGymnastData(userId: string): Observable<GymnastData> {
    return this.api.get(`gymnasts/${userId}`).pipe(
      map((response) => {
        response.dateOfBirth = DateUtils.convertServerStringToDate(
          response.dateOfBirth,
        );
        return response;
      }),
    );
  }

  invite(userId: string, dtbId: string): Observable<ServerResponse> {
    return this.api.post(
      `gymnasts/${userId}/invite/${this.userProfile.clubId}`,
      {
        gymnastId: userId,
        dtbId,
      },
    );
  }

  inviteForDTBID(userId: string): Observable<any> {
    return this.api.get(`gymnasts/${userId}/invite/dtb`);
  }

  getExistingInvitation(userId: string) {
    this.api
      .get(`gymnasts/${userId}/existinginvitation`)
      .pipe(
        map((result) => {
          let convertedDate = null;
          if (result) {
            // let data: InvitationStatus = result.payload[0];
            convertedDate =
              result.invitationStatus === "INVITED"
                ? DateUtils.convertServerStringToDate(result.date)
                : null;
          }
          return convertedDate;
        }),
        catchError(() => of(null)),
      )
      .subscribe((res) => this._existingInvitation.next(res));
  }

  getAnnualStampValidity(userId: string): void {
    forkJoin([
      this.api.get(`gymnasts/${userId}/stamps/current`),
      this.api.get(`gymnasts/${userId}/stamps/future`),
    ]).subscribe(([actualAnnualStampResponse, futureAnnualStampResponse]) => {
      let annualStamps: AnnualStamps = null;

      if (
        actualAnnualStampResponse &&
        actualAnnualStampResponse.validFrom &&
        actualAnnualStampResponse.validUntil
      ) {
        annualStamps = {
          actualValidFrom: DateUtils.convertServerStringToDate(
            actualAnnualStampResponse.validFrom + "",
          ),
          actualValidUntil: DateUtils.convertServerStringToDate(
            actualAnnualStampResponse.validUntil + "",
          ),
        };

        if (
          futureAnnualStampResponse &&
          futureAnnualStampResponse.validFrom &&
          futureAnnualStampResponse.validUntil
        ) {
          annualStamps = {
            ...annualStamps,
            futureValidFrom: DateUtils.convertServerStringToDate(
              futureAnnualStampResponse.validFrom + "",
            ),
            futureValidUntil: DateUtils.convertServerStringToDate(
              futureAnnualStampResponse.validUntil + "",
            ),
          };
        }
      }

      this._annualStampValidity.next(annualStamps);
    });
  }

  public get existingInvitation(): Observable<Date> {
    return this._existingInvitation.asObservable();
  }

  public get hasExistingInvitation(): boolean {
    return !isNullOrUndefined(this._existingInvitation.getValue());
  }

  public get annualStampValidity(): Observable<AnnualStamps> {
    return this._annualStampValidity.asObservable();
  }

  cleanUp() {
    this._existingInvitation.next(null);
    this._annualStampValidity.next(null);
  }

  getPartialGymnastData(
    userId: string,
  ): Observable<ServerResponse<PartialGymnastData>> {
    return this.api.get(`gymnasts/${userId}/candidate`).pipe(
      map((response) => {
        response.dateOfBirth = DateUtils.getDateString(DateUtils.convertServerStringToDate(response.dateOfBirth));
        return response;
      }),
    );
  }
}
