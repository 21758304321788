import { Role } from "@app/core/auth";
import { Profile } from "@app/core/auth/Profile.model";
import { AuthActions } from "./auth.actions";
import { AuthState } from "./auth.state";
import { ASSOCIATED_CLUBS_FETCHED } from "@app/store/club/club.actions";

export function authReducer(state: AuthState = new AuthState(), action: any) {
  switch (action.type) {
    case AuthActions.SIGN_IN:
      return { ...state, loading: true };
    case AuthActions.SET_AUTH:
      return { ...state, ...action.payload };
    case AuthActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        ...{
          user: new Profile({
            writable: true,
            role: Role.Gymnast,
            authorities: action.payload.user.authorities,
            uuid: action.payload.user.uuid,
          }),
          loading: false,
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
        },
      };
    case AuthActions.SIGN_IN_ERROR:
      return { ...state, loading: false };
    case AuthActions.SET_TOKEN:
      return {
        ...state,
        ...{
          user: new Profile({
            writable: true,
            role: Role.Gymnast,
            authorities: action.payload.user.authorities,
            uuid: action.payload.user.uuid,
            associatedClubs: [],
          }),
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
        },
      };
    case AuthActions.REFRESH_USER_SUCCESS:
      return { ...state, user: { name: action.payload.name } };
    case AuthActions.SIGN_OUT:
      return {};
    case ASSOCIATED_CLUBS_FETCHED:
      return {
        ...state,
        user: new Profile({ ...state.user, associatedClubs: action.payload }),
      };
    default:
      return state;
  }
}
