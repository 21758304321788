import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { AppState } from "@app/app.state";
import { AuthActions } from "@app/store/auth/auth.actions";
import { getToken, getUser, isLogged } from "@app/store/auth/auth.selectors";
import { Profile } from "@app/core/auth/Profile.model";
import { faqClubUrl, faqUrl } from "@app/views/legal/util/external-urls";
import { environment } from "@env/environment";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ClubData } from "@app/core/types/ClubData";
import { OpenActionCounterService } from "@app/views/user/components/show-open-actions/open-action-counter-service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    userProfile: Profile;
    isLogged$: Observable<boolean>;
    showNavigation = true;
    private unloadKey = "DT_DID_UNLOAD";
    isClubAdmin = false;
    isGymnast = false;
    club: ClubData;
    notificationCounter$

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private openActionCounterService: OpenActionCounterService
    ) {
        const didUnload = sessionStorage.getItem(this.unloadKey);

        this.isLogged$ = this.store.select(isLogged);
        this.isLogged$.pipe(take(1)).subscribe((is) => {
            if (is && !didUnload) {
                this.logout();
            }
        });

        this.store.select(getUser).subscribe((userProfile) => {
            this.userProfile = userProfile;
            this.isClubAdmin = userProfile.isClubAdmin;
            this.isGymnast = userProfile.isGymnast;
            this.club = userProfile.club;
        });
        this.store
            .select(getToken)
            .subscribe((token: string) => {
                openActionCounterService.subscribeToHost(token);
                this.notificationCounter$ = this.openActionCounterService.getNotificationCounter();
            });
    }

    ngOnInit() {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.showNavigation = false;
            }
        });
    }

    @HostListener("window:beforeunload")
    unloadHandler() {
        sessionStorage.setItem(this.unloadKey, "yes");
    }

    toggleNavigation() {
        this.showNavigation = !this.showNavigation;
    }

    closeNavigation() {
        this.showNavigation = false;
    }

    logout() {
        this.store.dispatch({type: AuthActions.SIGN_OUT});
        this.closeNavigation();
    }

    get faqLink(): string {
        if (
            this.userProfile.isClubAdmin ||
            this.userProfile.isCompetitionOrganizer
        ) {
            return faqClubUrl;
        } else {
            return faqUrl;
        }
    }

    isLogoFooterVisible() {
        return environment.testSystem;
    }
}
