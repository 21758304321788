import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    loadChildren: "./views/auth/index#AuthModule",
  },
  {
    path: "",
    loadChildren: "./views/user/index#UserModule",
  },
  {
    path: "",
    loadChildren: "./views/legal/index#LegalModule",
  },
  {
    path: "",
    loadChildren: "./views/error/index#ErrorModule",
  },
];
