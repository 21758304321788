export class CoreActions {
  static readonly INIT = "[CORE] INIT";
  static readonly SET_REDIRECT_URL = "[CORE] SET_REDIRECT_URL";
  static readonly REDIRECT_URL_RESET = "[CORE] REDIRECT_URL_RESET";
  static readonly SET_ONLINE = "[CORE] SET_ONLINE";
  static readonly SET_OFFLINE = "[CORE] SET_OFFLINE";
  static readonly SET_LANGUAGE = "[CORE] SET_LANGUAGE";
  static readonly LOADING = "[CORE] LOADING";
  static readonly LOADED = "[CORE] LOADED";
  static readonly SIDE_NAV_TOGGLE = "[CORE] SIDE_NAV_TOGGLE";
  static readonly SET_TITLE = "[CORE] SET_TITLE";
  static readonly EMPLOYEE_INVITED = "[CORE] EMPLOYEE_INVITED";
  static readonly HIDE_WELCOME_SECTION = "[CORE] HIDE_WELCOME_SECTION";
}
